import React, { useState, useEffect } from "react";
import { Row, Col, Input, Typography, Card, Table, Form } from "antd";

import SectionHeader from "../../../molecules/SectionHeader";
import fire from "../../../../utils/firebase";
import DashboardLayout from "../../../molecules/DashboardLayout";
import BreadCrumb from "../../../atoms/BreadCrumb";
import { EQARI_GOOGLE_KEY } from "../../../../configs/constants";
import { WrappedMap } from "../../../../utils/WrappedMap";
import { useDispatch, useSelector } from "react-redux";
import { getTechnicains } from "../Roster/components/TechnicianSchedule/ducks/actions";
import { getTechTicket } from "../ducks/actions";

const { Title, Text } = Typography;
const { Search } = Input;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    width: 135,
    title: "Current location",
    dataIndex: "location",
  },
];

const data = [
  {
    key: "1",
    name: "Ahmed Moustapha",
    location: "Deira",
  },
  {
    key: "2",
    name: "Fahad Iqbal",
    location: "Deira",
  },
  {
    key: "3",
    name: "Aziz Azeem",
    location: "Al Satwa",
  },
  {
    key: "4",
    name: "Mustafa",
    location: "Al Jaddaf",
  },
  {
    key: "5",
    name: "Chris Beck",
    location: "Deira",
  },
  {
    key: "6",
    name: "Nabil Salim",
    location: "JBR",
  },
  {
    key: "7",
    name: "Arjun Aryan",
    location: "Marina",
  },
  {
    key: "8",
    name: "Aditya Kabir",
    location: "Creek",
  },
  {
    key: "9",
    name: "Rohan Darsh",
    location: "Deira",
  },
  {
    key: "10",
    name: "Dev Pranav",
    location: "Deira",
  },
  {
    key: "11",
    name: "Arjun Aryan",
    location: "Marina",
  },
  {
    key: "12",
    name: "Aditya Kabir",
    location: "Creek",
  },
  {
    key: "13",
    name: "Rohan Darsh",
    location: "Deira",
  },
];

const breadcrumbList: any = [
  {
    label: "Maintenance",
    route: "/livetracking",
  },
  {
    label: "Live Tracking",
    route: "/livetracking",
  },
];

const list = {
  search: "",
  page: 0,
  limit: 10,
};

const ticketinit: any = {
  new: [],
  inprogress: [],
};

export default () => {
  const dispatch = useDispatch();
  let mapRef = fire.database().ref().limitToLast(100);
  const [marker, setMarker] = useState([]);
  const [allMarker, setAllMarker] = useState([]);
  const [infoText, setInfoText] = useState(null);
  const technician = useSelector((state) => state.rosterReducer.getTechnicians);
  const [ticketList, setTicketList] = useState(ticketinit);
  const [filterTable, setFilterTable] = useState();
  const [baseData, setBaseData]: any = useState();
  const [tempState, setTempState] = useState(null);
  const [addPinState, setAddPinState] = useState(null);
  const [removePinState, setRemovePinState] = useState(null);
  const newticket = useSelector((state) => state.maintenance.technicianTicket);
  const progticket = useSelector(
    (state) => state.maintenance.technicianTicket2,
  );

  const getTickets = (techid) => {
    dispatch(getTechTicket(techid, "new"));
    dispatch(getTechTicket(techid, "inProgress"));
  };

  const searchTech = (value) => {
    const filterTable = baseData.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase()),
      ),
    );

    setFilterTable(filterTable);
  };

  useEffect(() => {
    // dispatch(getTechnicains());
    return () => {
      mapRef.off();
    };
  }, []);

  useEffect(() => {
    if (technician) {
      setBaseData(
        technician.map((item) => {
          var lastname = item.Party.Person.last_name
            ? item.Party.Person.last_name
            : "";

          return {
            key: item.Party.id,
            name: `${item.Party.Person.first_name} ${lastname}`,
            location: "",
          };
        }),
      );
    }

    if (technician.length > 0) {
      mapRef.on("child_added", (snapshot) => {
        setAddPinState(snapshot);
      });

      mapRef.on("child_removed", (snapshot) => {
        setRemovePinState(snapshot);
      });

      mapRef.on("child_changed", (snapshot) => {
        setTempState(snapshot);
      });
    }
  }, [technician]);

  useEffect(() => {
    if (tempState) {
      updatePin(tempState);
    }
  }, [tempState]);

  useEffect(() => {
    if (addPinState) {
      addPin(addPinState);
    }
  }, [addPinState]);

  useEffect(() => {
    if (removePinState) {
      removePin(removePinState);
    }
  }, [removePinState]);

  const addPin = (snapshot) => {
    let obj = { text: snapshot.val(), id: snapshot.key };
    if (obj) {
      var temp1 = allMarker;
      if (technician.find((item) => obj.id === `user-${item.Party.id}`)) {
        temp1.push(obj);
        var e = baseData.find((item) => obj.id === `user-${item.key}`);
        e.location = obj.text.city ? obj.text.city : "";
        // setBaseData(Object.assign([], e));
        setAllMarker(Object.assign([], temp1));
      }
    }
  };

  const removePin = (snapshot) => {
    let obj = { text: snapshot.val(), id: snapshot.key };
    if (obj) {
      var temp1 = allMarker;
      var temp2 = marker;
      var index = allMarker.findIndex((item) => item.id === obj.id);
      var index1 = marker.findIndex((item) => obj.id === item.id);
      if (technician.find((item) => obj.id === `user-${item.Party.id}`)) {
        temp1.splice(index, 1);
        temp2.splice(index1, 1);
        setAllMarker(Object.assign([], temp1));
        setMarker(Object.assign([], temp2));
      }
    }
  };

  const updatePin = (snapshot) => {
    let obj = { text: snapshot.val(), id: snapshot.key };
    var temp = marker;
    var d = temp.findIndex((x) => {
      return x.id === obj.id;
    });
    if (d != -1) {
      temp[d].text = obj.text;
      var e = baseData.find((item) => obj.id === `user-${item.key}`);
      e.location = obj.text.city ? obj.text.city : "";
      setMarker(Object.assign([], temp));
    }
  };

  useEffect(() => {
    if (newticket) {
      var tmp = ticketList;
      tmp.new = newticket;
      setTicketList(Object.assign({}, tmp));
    }
    if (progticket) {
      var tmp1 = ticketList;
      tmp1.inprogress = progticket;
      setTicketList(Object.assign({}, tmp1));
    }
  }, [newticket, progticket]);

  const selectMark = (props) => {
    if (props) {
      setTicketList(ticketinit);
      setInfoText(null);
      getTickets(parseInt(props.id.replace("user-", "")));
      var temp: any = {
        firedata: {},
        info: {},
      };
      temp.firedata = props;
      temp.info = technician.find((item) => {
        return item.Party.id == props.id.replace("user-", "");
      });
      setInfoText(temp);
    } else {
      setTicketList(ticketinit);
      setInfoText(null);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      var temp = [];
      selectedRows.length > 0 &&
        selectedRows.forEach((item) => {
          var pin = allMarker.find((x) => x.id == `user-${item.key}`);
          if (pin) {
            temp.push(pin);
          }
        });
      setMarker(temp);
    },
  };

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <SectionHeader heading="Live Tracking" />
          <Card
            bordered={false}
            className="ag-nospace-head ag-nospace-body h-auto"
          >
            <Row>
              <Col span={7}>
                <Card bordered={false}>
                  <Title level={5} className="mb-1">
                    All Technicians ({technician && technician.length})
                  </Title>
                  <Form.Item>
                    <Search
                      placeholder="Search a technician"
                      allowClear
                      onSearch={searchTech}
                    />
                  </Form.Item>
                  <Table
                    className="w-100 noborder-table"
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    pagination={{
                      className: "ag-property-text-pagination mt-1",
                    }}
                    columns={columns}
                    dataSource={filterTable == null ? baseData : filterTable}
                  />
                </Card>
              </Col>
              <Col span={17}>
                <WrappedMap
                  isMarkerShown
                  positions={marker}
                  selected={selectMark}
                  ticketList={ticketList}
                  selectedPark={infoText}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${EQARI_GOOGLE_KEY}`}
                  loadingElement={<div style={{ height: "100%" }} />}
                  containerElement={<div style={{ height: "790px" }} />}
                  mapElement={<div style={{ height: "100%" }} />}
                  dashboard={false}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </DashboardLayout>
  );
};
