import React from "react";
import { Menu, Space, Typography, Tabs, Card, Divider, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import axios from "../../../utils/axiosInceptor";
import { leaseService } from "../../../configs/constants";
import moment from "moment";

const { Text } = Typography;
const { TabPane } = Tabs;

const NotificationPanel = ({
  notificationsData,
  newNotificationsData,
  setNotifiyDropDown,
  handleVisibleChange,
}) => {
  const history = useHistory();

  const handleLinkClick = async (item) => {
    window.localStorage.setItem(
      "selectedOffer",
      JSON.stringify({
        identifier: item.identifier_value,
        id: item.id,
      }),
    );
    const url = `${leaseService}/leads/notifications/update/status`;
    const postData = {
      notification_ids: [item.id],
    };
    const headers = {
      Authorization:
        "Bearer" +
        " " +
        JSON.parse(window.localStorage.getItem("login-response"))?.token,
    };
    await axios.post(url, postData, { headers });
    history.push({
      pathname: "/tickets",
      state: { identifier_value: item.identifier_value },
    });
    setNotifiyDropDown(false);
    handleVisibleChange(false);
  };
  return (
    <Card bordered={false} className="ag-nospace-body">
      <Tabs defaultActiveKey="1" tabBarGutter={24}>
        <TabPane
          tab={`New (${newNotificationsData[0]?.total_count || 0})`}
          key="1"
        >
          <Menu className="notif-panel" selectable={false}>
            {newNotificationsData.map((item, index) => (
              <Menu.Item
                key={index}
                className={`notif-item ${
                  item.status === "read" ? "notify-read" : ""
                }`}
              >
                <Space direction="vertical" size={4}>
                  <Text className="ag-primary">{item.text}</Text>
                  <Text className="smallFont12">
                    <Link
                      //to="/tickets"
                      className="ag-secondary"
                      onClick={() => handleLinkClick(item)}
                    >
                      {item.identifier || "Click here."}
                    </Link>
                  </Text>
                  <Text className="smallFont12">
                    {item.createdAt
                      ? moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")
                      : ""}
                  </Text>
                </Space>
              </Menu.Item>
            ))}
            {newNotificationsData[0]?.total_count > 5 && (
              <Menu.Item className="notif-item text-right">
                <Button
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: `/notifications`,
                      state: {
                        selected: "new",
                      },
                    })
                  }
                >
                  View All
                </Button>
              </Menu.Item>
            )}
          </Menu>
        </TabPane>
        <TabPane
          tab={`All (${notificationsData[0]?.total_count || 0})`}
          key="2"
        >
          <Menu className="notif-panel" selectable={false}>
            {notificationsData.map((item, index) => (
              <Menu.Item
                key={index}
                className={`notif-item ${
                  item.status === "read" ? "notify-read" : ""
                }`}
              >
                <Space direction="vertical" size={4}>
                  <Text className="ag-primary">{item.text || ""}</Text>
                  <Text className="smallFont12">
                    <Link
                      className="ag-secondary"
                      //to="/tickets"
                      onClick={() => handleLinkClick(item)}
                    >
                      {item.identifier || "Click here."}
                    </Link>
                  </Text>
                  <Text className="smallFont12">
                    {item.createdAt
                      ? moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")
                      : ""}
                  </Text>
                </Space>
              </Menu.Item>
            ))}
            {notificationsData[0]?.total_count > 5 && (
              <Menu.Item className="notif-item text-right">
                <Button
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: `/notifications`,
                      state: {
                        selected: "all",
                      },
                    })
                  }
                >
                  View All
                </Button>
              </Menu.Item>
            )}
          </Menu>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default NotificationPanel;
