import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Typography,
  message,
  Space,
  Spin,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { DateField, InputField, SelectField } from "../../../../../atoms/FormElement";
import { getAllAreas, getAreaStatus, getBuilding } from '../../ducks/actions';
import moment from "moment";
import { maintenanceService } from "../../../../../../configs/constants";
import axios from '../../../../../../utils/axiosInceptor'

const defaultValues: any = {
  zone_name: '',
  region_name: '',
  date_of_addition: '',
  zone_status_type_name: '',
};

const { Paragraph } = Typography;

const AddZone = props => {

  const dispatch = useDispatch();
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const areaApi = useSelector(state => state.zones.areasData)
  const areastatusApi = useSelector(state => state.zones.statusData)
  const buildingApi = useSelector(state => state.zones.buildingData);
  const { control, errors, reset, handleSubmit } = useForm({ defaultValues });


  useEffect(() => {
    dispatch(getAllAreas())
    dispatch(getAreaStatus())
    dispatch(getBuilding())
  }, [])


  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    console.log(values);
    const { zone_name, region_name, date_of_addition, zone_status_type_name } = values;
    const postJson = {
      zone_name,
      region_name: region_name.label,
      date_of_addition,
      zone_status_type_name: zone_status_type_name.label,
    }
    let buildings = values.properties.map(item => (item.value))

    const url = `${maintenanceService}/maintenance/`;
    try {
      const resp = await axios.post(`${url}createMaintenanceZone`, postJson);
      let zoneJson = {
        zone_id: resp.data?.result?.zone?.id,
        building_ids: buildings,
      }
      await axios.post(`${url}createZoneBuildings`, zoneJson);
      setTimeout(() => {
        reset();
        setLoader(false);
        message.success('Zone added successfully');
        setSubmited(false);
        props.onSubmit();
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message)
      console.log("check", response);
    }
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError &&
        (<Row gutter={[24, 24]} >
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>)}
      {!submited ?
        (<>

          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="zone_name"
                label="Name of Zone"
                control={control}
                rules={{
                  required: "Please enter zone name",
                }}
                iProps={{ placeholder: "Al Barsha" }}
                initValue=""
                validate={errors.zone_name && "error"}
                validMessage={errors.zone_name && errors.zone_name.message}
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="region_name"
                label="Area"
                control={control}
                iProps={{ placeholder: 'Select Area' }}
                rules={{ required: "Please select area" }}
                initValue=''
                selectOption={areaApi.length > 0 && areaApi.map(item => ({ value: item.id, label: item.name }))}
                validate={errors.region_name && "error"}
                validMessage={errors.region_name && errors.region_name.message}
              />
            </Col>
            <Col span={12}>
              <DateField
                isRequired={true}
                fieldname="date_of_addition"
                label="Date of Activation"
                control={control}
                iProps={{ placeholder: "DD-MM-YYYY", format: "DD-MM-YYYY" }}
                rules={{
                  required: "Please enter date",
                  setValueAs: (value) => moment(value).format('YYYY-MM-DD'),
                }}
                initValue=""
                validate={errors.date_of_addition && "error"}
                validMessage={errors.date_of_addition && errors.date_of_addition.message}
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="zone_status_type_name"
                label="Status"
                control={control}
                iProps={{ placeholder: 'Select Status' }}
                rules={{ required: "Please select status" }}
                initValue=''
                selectOption={areastatusApi.length > 0 && areastatusApi.map(item => ({ value: item.id, label: item.name }))}
                validate={errors.zone_status_type_name && "error"}
                validMessage={errors.zone_status_type_name && errors.zone_status_type_name.message}
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="properties"
                label="Assign a building"
                control={control}
                iProps={{ placeholder: 'Select buildings', isMulti: true }}
                rules={{ required: "Please select buildings" }}
                initValue=''
                selectOption={buildingApi?.map(item => ({ value: item.id, label: item.name }))}
                validate={errors.properties && "error"}
                validMessage={errors.properties && errors.properties.message}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" htmlType="button" onClick={props.onCancel}>Back</Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">Add</Button>
            </Col>
          </Row>
        </>
        )
        :
        isError &&
        (<>
          <Row gutter={[24, 24]} >
            <Col span={24}>
              <Space size={24} className="w-100 text-center" direction="vertical">
                <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">{errorMessage}</Paragraph>
              </Space>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}><Button className="ag-gray-button-outline" htmlType="button" onClick={() => { setSubmited(false); setisError(false) }}>Back</Button></Col>
          </Row>
        </>)
      }
    </Form>
  );
};

export default AddZone;