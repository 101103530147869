import { combineReducers } from "redux";
import { LOGOUT_SUCCESS } from "../../app/modules/Login/duck/constants";
import auth from "../../app/modules/Login/duck/reducer";
import signup from "../../app/modules/SignUp/duck/reducer";
import tickets from "../../app/modules/Maintenance/Tickets/ducks/reducer";
import zones from "../../app/modules/Maintenance/Zones/ducks/reducers";
import header from "../../app/molecules/Header/duck/reducer";
import rosterReducer from "../../app/modules/Maintenance/Roster/components/TechnicianSchedule/ducks/reducers";
import technicians from "../../app/modules/Maintenance/Technicians/ducks/reducers";
import maintenance from "../../app/modules/Maintenance/ducks/reducers";
import tickettypes from "../../app/modules/Maintenance/TicketTypes/ducks/reducers";
import notification from "../../app/modules/Notification/ducks/reducers";

const appReducer = combineReducers({
  auth,
  header,
  signup,
  tickets,
  zones,
  rosterReducer,
  technicians,
  tickettypes,
  maintenance,
  notification
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === LOGOUT_SUCCESS) state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
