import * as actionTypes from "./types";
import axios from "../../../../../../../utils/axiosInceptor";
import { maintenanceService } from "../../../../../../../configs/constants";

export const getAllDayEntries = () => {
  return async (dispatch) => {
    const url = `${maintenanceService}/maintenance/getAllTimeEntryStatusTypes`;
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_DAYENTRIES,
      data: response?.data?.result,
    });
  };
};

export const getTechnicains = (page, limit, search) => {
  return async (dispatch) => {
    const url = `${maintenanceService}/facilityManager/getFacilityManagerTechnicians?limit=${limit}&page=${page}${
      search ? `&name=${search}` : ""
    }`;
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_TECHNICIANS,
      data: response?.data.result,
    });
  };
};

export const getTechnicainDrop = () => {
  return async (dispatch) => {
    const url = `${maintenanceService}/facilityManager/getTechniciansList`;
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_TECHNICIAN_DROP,
      data: response?.data.result,
    });
  };
};

export const getAllDutyRoster = (startDate, endDate, partId = []) => {
  return async (dispatch) => {
    const url = `${maintenanceService}/maintenance/getDutyRoster?start_date=${startDate}&end_date=${endDate}`;
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_DUTYROSTER,
      data: response?.data?.result,
    });
  };
};
//merged
