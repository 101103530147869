import * as actionTypes from "./types";

const initialState = {
  getAllDatStatus: [],
  getTechnicians: [],
  getAllDutyRoster: [],
  technicianList: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case actionTypes.GET_DAYENTRIES: {
      return {
        ...state,
        getAllDatStatus: data,
      };
    }
    case actionTypes.GET_TECHNICIANS: {
      return {
        ...state,
        getTechnicians: data,
      };
    }
    case actionTypes.GET_TECHNICIAN_DROP: {
      return {
        ...state,
        technicianList: data,
      };
    }
    case actionTypes.GET_DUTYROSTER: {
      return {
        ...state,
        getAllDutyRoster: data,
      };
    }
    default:
      return state;
  }
};
