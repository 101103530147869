import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Space, Button, List } from "antd";
import { EyeFilled, CloseOutlined, CaretRightFilled } from "@ant-design/icons";
import ThumbImg from "../../../../../../assets/img/ticket_thumb.jpg";
import { maintenanceService } from "../../../../../../configs/constants";
import axios from "../../../../../../utils/axiosInceptor";
import moment from "moment";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { getTicketReviews } from "../../ducks/actions";
import Icon from "@ant-design/icons";

const { Title, Text } = Typography;

const MarkSvg = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.63547 12.9596C5.60734 12.9596 5.58078 12.9581 5.55265 12.9534C5.3589 12.9268 5.19015 12.8127 5.09328 12.644L2.60422 8.28931C2.43234 7.98931 2.53703 7.60806 2.83703 7.43618C3.13703 7.26431 3.51828 7.369 3.69015 7.669L5.7714 11.3127L13.4433 3.61743C13.687 3.37368 14.0823 3.37212 14.3277 3.61587C14.5714 3.85962 14.573 4.25493 14.3292 4.50025L6.10734 12.7471L6.07765 12.7768C5.9589 12.8956 5.79953 12.9596 5.63547 12.9596Z"
      fill="#1C5D3B"
    />
  </svg>
);
const MarkIcon = (props) => <Icon component={MarkSvg} {...props} />;

const PendingSvg = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8.52148" cy="8.15955" r="7.5" fill="white" stroke="#1C5D3B" />
    <circle cx="8.52102" cy="8.15957" r="3.98977" fill="#1C5D3B" />
  </svg>
);
const PendingIcon = (props) => <Icon component={PendingSvg} {...props} />;

const BackSvg = () => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.41994 13.0598C8.25755 13.0598 8.09346 13.0057 7.95644 12.8974L0.787449 7.13078C0.613215 6.99038 0.511719 6.77893 0.511719 6.55395C0.511719 6.33066 0.613215 6.11752 0.787449 5.97881L7.92599 0.242597C8.24401 -0.012834 8.7092 0.037914 8.96463 0.355934C9.22006 0.673955 9.16932 1.13914 8.8513 1.39458L2.42999 6.55395L8.88344 11.7455C9.20146 12.0009 9.2522 12.4661 8.99677 12.7841C8.8496 12.9651 8.63646 13.0598 8.41994 13.0598Z"
      fill="#184461"
    />
  </svg>
);
const BackIcon = (props) => <Icon component={BackSvg} {...props} />;

const ActivityLog = (props) => {
  const { data, onCancel, assignTech, onActivityLog, onBack } = props;

  const dispatch = useDispatch();
  const [activityData, setActivityData] = useState([]);
  const ticketReviews = useSelector((state) => state.tickets.getTicketReviews);

  let tickid = data.case_id;
  // let category_id = data.CaseCategoryClassification?.[0]?.case_category_id;
  // let sub_category_id = data.CaseCategorySecondaryClassification?.[0]?.case_category_id;

  useEffect(() => {
    const url = `${maintenanceService}/maintenance/getTicketLog?ticket_id=${tickid}`;

    axios.get(url).then(
      (response) => {
        setActivityData(response?.data?.result);
      },
      (error) => {
        console.log(error);
      },
    );
  }, [data]);

  const listData = [
    {
      title: "Ant Design Title 1",
    },
    {
      title: "Ant Design Title 2",
    },
    {
      title: "Ant Design Title 3",
    },
    {
      title: "Ant Design Title 4",
    },
  ];
  // const { type, subtype, status, tenant, appartment, description, technician, priority, datetime, id, confirmation_status, recuring } = data;
  return (
    <Row gutter={24}>
      <Col span={24}>
        <Button
          className="p-0 h-auto w-auto ag-default mb-1"
          type="link"
          size="large"
          onClick={onCancel}
          icon={<CloseOutlined />}
        />
      </Col>
      <Col span={24}>
        <Button
          className="p-0 h-auto w-auto ag-primary mb-1"
          type="link"
          size="large"
          onClick={onBack}
        >
          <BackIcon /> Back to ticket details
        </Button>
      </Col>
      <Col span={24}>
        <Space size={24} className="w-100" direction="vertical">
          <Title level={3}> Activity Log</Title>
        </Space>
      </Col>
      <Col span={24}>
        <List
          itemLayout="horizontal"
          dataSource={activityData}
          renderItem={(item) => (
            <List.Item
              className={`activity-log-item ${item.isActive ? "active" : ""}`}
            >
              <List.Item.Meta
                avatar={item.isActive ? <PendingIcon /> : <MarkIcon />}
                title={item.ticket_status}
              />
              <div>
                {moment(item.createdAt).format("HH:mm") + " |"}{" "}
                {moment(item.createdAt).format("DD MMM YYYY")}
              </div>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};

export default ActivityLog;
