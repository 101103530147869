import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Tabs,
  Typography,
  Breadcrumb,
  Card,
  Table,
  DatePicker,
} from "antd";
import { SearchOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "@ant-design/charts";

import { Link } from "react-router-dom";
import Sidebar from "../../../molecules/Sidebar";
import Header from "../../../molecules/Header";
import Footer from "../../../molecules/Footer";
import Grid from "../../../atoms/Grid/";
import { Popup } from "../../../atoms/Popup";
import SectionHeader from "../../../molecules/SectionHeader";
import AddTicketTypes from "./components/AddTicketTypes";
import { getTypeCount } from "./ducks/actions";
import DashboardLayout from "../../../molecules/DashboardLayout";
import BreadCrumb from "../../../atoms/BreadCrumb";
import moment from "moment";

const { Column: TableColumn } = Table;
const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const gridProps = {
  sortable: false,
  filter: false,
};

const typeCol = [
  {
    headerName: "#",
    valueGetter: "node.rowIndex + 1",
    maxWidth: 150,
  },
  {
    headerName: "Ticket Type",
    field: "tickettype",
  },
  {
    headerName: "Technicians",
    field: "technicians",
  },
  {
    headerName: "Number of Tickets",
    field: "numberoftickets",
  },
  {
    headerName: "% of Success",
    field: "successpercent",
    maxWidth: 200,
  },
];

const subTypeCol = [
  {
    headerName: "#",
    valueGetter: "node.rowIndex + 1",
    maxWidth: 150,
  },
  {
    headerName: "Ticket Sub-Type",
    field: "subtype",
  },
  {
    headerName: "Technicians",
    field: "technicians",
  },
  {
    headerName: "Number of Tickets",
    field: "numberoftickets",
  },
  {
    headerName: "% of Success",
    field: "successpercent",
    maxWidth: 200,
  },
];

const ticketApi = [
  {
    tickettype: "Plumbing",
    subtype: "Water-Leakage",
    technicians: 4,
    numberoftickets: 12,
    successpercent: 30,
  },
  {
    tickettype: "Plumbing",
    subtype: "Sink Nozzle",
    technicians: 9,
    numberoftickets: 21,
    successpercent: 20,
  },
  {
    tickettype: "Plumbing",
    subtype: "Flush problem",
    technicians: 5,
    numberoftickets: 21,
    successpercent: 15,
  },
  {
    tickettype: "Plumbing",
    subtype: "Foul Smell",
    technicians: 13,
    numberoftickets: 43,
    successpercent: 30,
  },
  {
    tickettype: "Plumbing",
    subtype: "Leakage",
    technicians: 12,
    numberoftickets: 43,
    successpercent: 20,
  },

  {
    tickettype: "AC",
    subtype: "Leaking ducts",
    technicians: 11,
    numberoftickets: 12,
    successpercent: 10,
  },
  {
    tickettype: "AC",
    subtype: "Fan problems",
    technicians: 7,
    numberoftickets: 21,
    successpercent: 22,
  },
  {
    tickettype: "AC",
    subtype: "Clogged drains",
    technicians: 3,
    numberoftickets: 21,
    successpercent: 50,
  },

  {
    tickettype: "Carpenter",
    subtype: "Fix Furniture",
    technicians: 18,
    numberoftickets: 15,
    successpercent: 12,
  },
  {
    tickettype: "Carpenter",
    subtype: "Fix Door Frame",
    technicians: 10,
    numberoftickets: 11,
    successpercent: 30,
  },

  {
    tickettype: "Paint",
    subtype: "Rust",
    technicians: 18,
    numberoftickets: 15,
    successpercent: 12,
  },
  {
    tickettype: "Paint",
    subtype: "Scratches",
    technicians: 10,
    numberoftickets: 11,
    successpercent: 30,
  },

  {
    tickettype: "Electric",
    subtype: "Switch Problem",
    technicians: 18,
    numberoftickets: 15,
    successpercent: 12,
  },
  {
    tickettype: "Electric",
    subtype: "Power Problem",
    technicians: 10,
    numberoftickets: 11,
    successpercent: 30,
  },

  {
    tickettype: "Masonry",
    subtype: "Chimney Repair",
    technicians: 18,
    numberoftickets: 15,
    successpercent: 12,
  },
  {
    tickettype: "Masonry",
    subtype: "Cement repairs",
    technicians: 10,
    numberoftickets: 11,
    successpercent: 30,
  },
];

const initType: any = [
  {
    tickettype: "",
    numberoftickets: 0,
  },
];

const breadcrumbList: any = [
  {
    label: "Maintenance",
    route: "/ticket-types",
  },
  {
    label: "Ticket Types",
    route: "/ticket-types",
  },
];

export default () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [tabKey, setTabkey] = useState("1");
  const [tabs, setTabs] = useState([]);
  const [allType, setAllType] = useState([]);
  const [graphData, setGraphData] = useState(initType);
  const [value, setValue] = useState();
  const ticketTypeCount = useSelector((state) => state.tickettypes.typeCount);
  const searchField = (
    <DatePicker
      onChange={(e) => onSearch(e)}
      value={value}
      picker="year"
      style={{ width: 250 }}
    />
  );

  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Add a Ticket Type
        </Title>
      ),
      content: (
        <AddTicketTypes onCancel={() => setVisible(false)} onAdd={onClickAdd} />
      ),
      width: 650,
    },
  ];

  const createType = (data) => {
    let filterData = data.map((item) => {
      var x = { ...item };
      delete x["subtype"];
      return x;
    });
    var res = Object.values(
      filterData.reduce(
        (acc, { technicians, numberoftickets, successpercent, ...r }) => {
          var key = Object.entries(r).join("-");
          acc[key] = acc[key] || {
            ...r,
            numberoftickets: 0,
            technicians: 0,
            successpercent: 0,
          };
          return (
            (acc[key].numberoftickets += numberoftickets),
            (acc[key].technicians += technicians),
            (acc[key].successpercent += successpercent),
            acc
          );
        },
        {},
      ),
    );
    setAllType(res);
  };

  const createSubType = (data) => {
    let result = data.reduce(function (r, a) {
      r[a["tickettype"]] = r[a["tickettype"]] || [];
      r[a["tickettype"]].push(a);
      return r;
    }, Object.create(null));
    setTabs(result);
  };

  const normalizeData = () => {
    var temp = [];
 

    var ticketTypeOnlyCount = Object.values(ticketTypeCount)
    var sum:any = ticketTypeOnlyCount.reduce((a:any, b:any) => a + b, 0);
    
    Object.entries(ticketTypeCount).forEach(([key, value ]) => {
      var a = {};
      var numberofticketValue:any = value;
      let percantage = (numberofticketValue)/sum*100;
      a["tickettype"] = key;
      a["numberoftickets"] = numberofticketValue;
      a["numberofticketsWithPercentage"] = `${value} ${'('+percantage.toFixed(2)+'%)'}`;
      temp.push(a);
    });
    setGraphData(temp);
  };

  useEffect(() => {
    createSubType(ticketApi);
    createType(ticketApi);
    var y: any = moment(new Date(), "YYYY");
    setValue(y);
    dispatch(getTypeCount(y.format("YYYY")));
  }, []);

  useEffect(() => {
    ticketTypeCount && normalizeData();
  }, [ticketTypeCount]);

  var chartConfig = {
    // renderer: "svg",
    appendPadding: 16,
    height: 250,
    data: graphData,
    xField: "tickettype",
    yField: "numberoftickets",
    color: "#184461",
    columnWidthRatio: 0.2,
    label: {
      labelEmit: true,
      // position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0,
      },
    },
    meta: {
      tickettype: {
         alias: "Ticket Type",
      },
      numberoftickets: { 
        alias: "No of Tickets",
        formatter: function formatter(value) {
          var ticketTypeOnlyCount = Object.values(ticketTypeCount)
          var sum:any = ticketTypeOnlyCount.reduce((a:any, b:any) => a + b, 0);
          var numberofticketValue:any = value;
          let percantage = (numberofticketValue)/sum*100;
          return `${value} ${'('+percantage.toFixed(2)+'%)'}`;
        },
      },
    },
    columnStyle: {
      fill: "#184461",
    },
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: "#D2D2D2",
            lineWidth: 1,
            lineDash: [5, 4],
          },
        },
      },
    },
  };

  const popupProps = {
    closable: false,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  function AddNewTicketType() {
    setTrigger(0);
    setVisible(true);
  }

  function onClickAdd() {}

  const onSearch = (date) => {
    setValue(date);
    if (date) {
      dispatch(getTypeCount(date.format("YYYY")));
    }
  };

  const generateTabs = (data) => {
    return (
      data &&
      Object.entries(data).map(([key, value], index) => (
        <React.Fragment key={index}>
          <TabPane tab={key} key={index + 2}>
            <Grid
              data={value}
              columns={subTypeCol}
              pagination={false}
              defaultSettings={gridProps}
              noRowlabel="Ticket Type"
              noRowbtnAction={AddNewTicketType}
            />
          </TabPane>
        </React.Fragment>
      ))
    );
  };

  return (
    <>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Ticket Types"
              //addNewAction={AddNewTicketType}
            />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Card
                  bordered={false}
                  className="extra-left"
                  extra={searchField}
                >
                  <Row gutter={24}>
                    <Col span={18}>
                      <Paragraph className="ag-default mx-2">
                        No. of orders this year
                      </Paragraph>
                    </Col>
                    <Col span={6}>
                      <Paragraph className="ag-default mx-2">
                        All Types:
                      </Paragraph>
                    </Col>
                    <Col span={18}>
                      <Column {...chartConfig} />
                    </Col>
                    <Col span={6}>
                      <Table
                        dataSource={graphData}
                        pagination={false}
                        bordered={false}
                        showHeader={false}
                        className="mx-2 sideTable"
                        size="small"
                      >
                        <TableColumn
                          render={(text, record, index) => (
                            <span key={index}>{index + 1}</span>
                          )}
                          dataIndex="index"
                          key="index"
                          className="numberStyle no-border"
                        />
                        <TableColumn
                          dataIndex="tickettype"
                          key="tickettype"
                          className="no-border"
                        />
                        <TableColumn
                          dataIndex="numberofticketsWithPercentage"
                          key="numberofticketsWithPercentage"
                          className="no-border"
                        />
                      </Table>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* <Col span={24}>
                <Card
                  bordered={false}
                  className="ag-nospace-head ag-nospace-body h-auto"
                >
                  <Tabs
                    activeKey={tabKey}
                    onChange={setTabkey}
                    tabBarGutter={48}
                    className="ag-property-status"
                  >
                    <TabPane tab="All" key="1">
                      <Grid
                        data={allType}
                        columns={typeCol}
                        pagination={false}
                        defaultSettings={gridProps}
                        noRowlabel="Ticket Type"
                        noRowbtnAction={AddNewTicketType}
                      />
                    </TabPane>
                    {tabs ? generateTabs(tabs) : null}
                  </Tabs>
                </Card>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};
