import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ROOT } from "../configs/routeNames";
import "../styles/antd.less";
import "../styles/styles.scss";

import Pages from "./Pages";

const Root = () => (
  <Router>
    <Switch>
      <Route path={ROOT} component={Pages} />
    </Switch>
  </Router>
);
export default Root;
