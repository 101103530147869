import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  DashboardOutlined,
  ProfileOutlined,
  HomeOutlined,
  FormOutlined,
  FileTextOutlined,
  ToolOutlined,
  TeamOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props) => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (isCollapsed) => {
    setCollapsed(isCollapsed);
  };

  const CustomTrigger = () =>
    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />;

  // render() {
  return (
    <Fragment>
      <Sider
        width="236"
        trigger={<CustomTrigger />}
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <Menu theme="dark" defaultOpenKeys={["2"]} mode="inline">
          {/* <Menu.Item key="1" icon={<DashboardOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item> */}
          {/* <Menu.Item key="2" icon={<ProfileOutlined />}>
            Listing
          </Menu.Item> */}
          <SubMenu key="2" icon={<HomeOutlined />} title="Maintenance">
            <Menu.Item key="3">
              <Link to="/tickets">Tickets</Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/technicians">Technicians</Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/zones">Zones</Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/roster">Roster</Link>
            </Menu.Item>
            {/* <Menu.Item key="7">
              <Link to="/livetracking">Live Tracking</Link>
            </Menu.Item> */}
            <Menu.Item key="8">
              <Link to="/ticket-types">Ticket Types</Link>
            </Menu.Item>
            {/* <Menu.Item key="5">Import/Export</Menu.Item> */}
          </SubMenu>
          {/* <SubMenu key="reviews" icon={<FormOutlined />} title="Reviews">
            <Menu.Item key="6">View</Menu.Item>
            <Menu.Item key="8">Export</Menu.Item>
          </SubMenu> */}
          {/* <SubMenu key="tenants" icon={<TeamOutlined />} title="Tenants">
            <Menu.Item key="9">Sub menu 1</Menu.Item>
            <Menu.Item key="10">Sub menu 2</Menu.Item>
          </SubMenu> */}
          {/* <SubMenu
            key="contracts"
            icon={<FileTextOutlined />}
            title="Contracts"
          >
            <Menu.Item key="11">Sub menu 1</Menu.Item>
            <Menu.Item key="12">Sub menu 2</Menu.Item>
          </SubMenu> */}
          {/* <SubMenu
            key="maintenance"
            icon={<ToolOutlined />}
            title="Maintenance"
          >
            <Menu.Item key="13">Sub menu 1</Menu.Item>
            <Menu.Item key="14">Sub menu 2</Menu.Item>
            <Menu.Item key="15">Sub menu 3</Menu.Item>
            <Menu.Item key="16">Sub menu 4</Menu.Item>
            <Menu.Item key="17">Sub menu 5</Menu.Item>
          </SubMenu> */}
          {/* <SubMenu
            key="leadManagement"
            icon={<ToolOutlined />}
            title="Lead Management"
          >
            <Menu.Item key="13">
              <Link to="/leads">All leads</Link>
            </Menu.Item>
          </SubMenu> */}
        </Menu>
      </Sider>
    </Fragment>
  );
  // }
};

export default Sidebar;
