import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Space, message, Typography, Spin } from "antd";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getAllDayEntries } from "./ducks/actions";
import {
  SelectField,
  DateField,
  TimeField,
} from "../../../../../atoms/FormElement";
import axios from "../../../../../../utils/axiosInceptor";
import { maintenanceService } from "../../../../../../configs/constants";
import moment from "moment";

const { Paragraph } = Typography;

const defaultValues: any = {
  fieldName: null,
  date: null,
  headerName: null,
  party_id: null,
  schedule_entry_status: null,
  defautlScheduleEntryStatus: null,
  startTime: null,
  endTime: null,
};

const EditTechnicianSchedule = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [option, setOption] = useState(null);
  const [selected, setSelected] = useState(false);
  const [
    defautlScheduleEntryStatus,
    setTefautlScheduleEntryStatus,
  ] = useState();
  const [technicianFormData, setTechnicianFormData] = useState(defaultValues);
  const dispatch = useDispatch();

  const { control, handleSubmit, errors, reset, watch, setValue } = useForm({
    defaultValues,
  });

  const onBack = () => {
    setOption(null);
    setSelected(false);
  };

  const datValues = useSelector((state) => state.rosterReducer.getAllDatStatus);

  useEffect(() => {
    dispatch(getAllDayEntries());
  }, []);

  // useEffect(() => {
  //   if (checkTech) {
  //     let em = techniciansData.filter(item => item.Party.id === checkTech.value)
  //     checkTech && setValue("email_address", em[0]?.Party?.PartyContactMechanismEmail[1]?.ContactMechanism?.ElectronicAddress?.email)
  //   }
  // }, [checkTech])

  // useEffect(() => {
  //   if (techniciansData) {
  //     setTechnicianData(
  //       techniciansData.map((item) => ({
  //         value: item.Party.id,
  //         label: item.Party.name,
  //       })),
  //     );
  //   }
  // }, [techniciansData]);

  useEffect(() => {
    reset({ defaultValues });
    props?.setOnClose(false);
  }, [props?.onClose]);

  // const onFirstForm = (values) => {
  //   option === "Multiple" && setTechnicians(values.technician);
  //   setSelected(true);
  // };

  const getSelectedDay = (day) => {
    let dayStatus = {};
    props?.rosterEditData?.data?.dayStatus.map((item) => {
      if (day === item.day) {
        dayStatus = {
          label: item.status,
          value: item.statusId,
        };
      }
    });

    return dayStatus;
  };

  useEffect(() => {
    setTechnicianFormData({
      fieldName: props?.rosterEditData?.colDef?.field,
      date:
        props?.rosterEditData?.data?.dayAndDate[
          props?.rosterEditData?.colDef?.field
        ],
      headerName: props?.rosterEditData?.colDef?.headerName,
      party_id: props?.rosterEditData?.data?.technicianId,
      defautlScheduleEntryStatus: getSelectedDay(
        props?.rosterEditData?.colDef?.field,
      ),
      startTime:
        props?.rosterEditData?.data.dayTime[
          props?.rosterEditData?.colDef?.field
        ][0],
      endTime:
        props?.rosterEditData?.data.dayTime[
          props?.rosterEditData?.colDef?.field
        ][1],
    });
  }, [props]);

  useEffect(() => {
    console.log(technicianFormData);
  }, [technicianFormData]);

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);

    values.schedule_entry_status = values.schedule_entry_status.label;
    values.timesheet_date = moment(technicianFormData.date).format(
      "YYYY-MM-DD",
    );
    values.party_id = technicianFormData.party_id;
    let schedule_entries = [values];
    console.log(JSON.stringify(values));

    const url = `${maintenanceService}/maintenance/updateDutyRoster`;
    try {
      await axios.put(url, { schedule_entries: schedule_entries });
      setLoader(false);
      setSubmited(false);
      message.success(`Technician Schedule Update`);
      setTechnicianFormData(defaultValues);
      reset({ defaultValues });
      onBack();
      props.onSubmit();
    } catch (e) {
      const { response } = e;
      setLoader(false);
      setisError(true);
      message.error(`Technician Schedule Update`);
      setErrorMessage(response?.data?.message);
    }
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        {loader && !isError && (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Space
                size={24}
                className="w-100 text-center"
                direction="vertical"
              >
                <Spin size="large" className="ag-fontSize32" />
                {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
              </Space>
            </Col>
          </Row>
        )}

        {!submited ? (
          <>
            <Row gutter={24}>
              {/* <Col span={12}>
                    <SelectField
                      isRequired={true}
                      fieldname="technician2"
                      label="Add technician"
                      control={control}
                      iProps={{ placeholder: "Select Technician" }}
                      rules={{
                        required: "Please select technicians",
                      }}
                      initValue={props?.rosterEditData?.technicianId}
                      selectOption={technicianData}
                      setValue={setValue}
                      // valueGot= {{
                      //   label:props?.rosterEditData?.technicianName,
                      //   value:props?.rosterEditData?.technicianId
                      // }}
                      validate={errors.technician2 && "error"}
                      validMessage={
                        errors.technician2 && errors.technician2.message
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      fieldname="email_address"
                      label="Email Addresss"
                      control={control}
                      iProps={{ readOnly: true, bordered: false }}
                    />
                  </Col> */}
              <Col span={12}>
                <DateField
                  isRequired={true}
                  fieldname="start_date"
                  label="Date From"
                  control={control}
                  iProps={{
                    placeholder: "DD-MM-YYYY",
                    format: "DD-MM-YYYY",
                    disabled: true,
                  }}
                  rules={{
                    required: "Please enter from date",
                    setValueAs: (value) => moment(value).format("YYYY-MM-DD"),
                  }}
                  initValue=""
                  disabled
                  setValue={setValue}
                  valueGot={moment(
                    props?.rosterEditData?.data?.starting_date,
                    "DD-MM-YYYY",
                  )}
                  validate={errors.start_date && "error"}
                  validMessage={errors.start_date && errors.start_date.message}
                />
              </Col>
              <Col span={12}>
                <DateField
                  isRequired={true}
                  fieldname="end_date"
                  label="Date To"
                  control={control}
                  iProps={{
                    placeholder: "DD-MM-YYYY",
                    format: "DD-MM-YYYY",
                    disabled: true,
                  }}
                  rules={{
                    required: "Please enter to date",
                    setValueAs: (value) => moment(value).format("YYYY-MM-DD"),
                  }}
                  initValue=""
                  setValue={setValue}
                  valueGot={moment(
                    props?.rosterEditData?.data?.ending_date,
                    "DD-MM-YYYY",
                  )}
                  validate={errors.end_date && "error"}
                  validMessage={errors.end_date && errors.end_date.message}
                />
              </Col>
              <Col span={12}>
                <TimeField
                  isRequired={true}
                  fieldname="starting_time"
                  label="Shift starting Time"
                  control={control}
                  rules={{
                    required: "Please enter start time",
                    setValueAs: (value) => moment(value).format("HH:mm"),
                  }}
                  initValue=""
                  iProps={{ format: "HH:mm" }}
                  setValue={setValue}
                  valueGot={moment(technicianFormData?.startTime, "HH:mm:ss")}
                  validate={errors.starting_time && "error"}
                  validMessage={
                    errors.starting_time && errors.starting_time.message
                  }
                />
              </Col>
              <Col span={12}>
                <TimeField
                  isRequired={true}
                  fieldname="ending_time"
                  label="Shift end Time"
                  control={control}
                  rules={{
                    required: "Please enter end time",
                    setValueAs: (value) => moment(value).format("HH:mm"),
                  }}
                  initValue=""
                  iProps={{ format: "HH:mm" }}
                  validate={errors.ending_time && "error"}
                  setValue={setValue}
                  valueGot={moment(technicianFormData?.endTime, "HH:mm:ss")}
                  validMessage={
                    errors.ending_time && errors.ending_time.message
                  }
                />
              </Col>
              <Col span={12}>
                <SelectField
                  isRequired={true}
                  fieldname="schedule_entry_status"
                  label={technicianFormData.headerName}
                  control={control}
                  iProps={{ placeholder: "Select day status" }}
                  rules={{ required: "Please select day status" }}
                  initValue=""
                  selectOption={datValues.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  setValue={setValue}
                  valueGot={technicianFormData?.defautlScheduleEntryStatus}
                  validate={errors.schedule_entry_status && "error"}
                  validMessage={
                    errors.schedule_entry_status &&
                    errors.schedule_entry_status.message
                  }
                />
              </Col>
              {/* <Col span={12}>
                    <SelectField
                      isRequired={true}
                      fieldname="monday"
                      label="Monday"
                      control={control}
                      iProps={{ placeholder: "Select day status" }}
                      rules={{ required: "Please select day status" }}
                      initValue=''
                      setValue={setValue}
                      valueGot= {getSelectedDay('monday')}
                      selectOption={datValues.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      validate={errors.monday && "error"}
                      validMessage={errors.monday && errors.monday.message}
                    />
                  </Col>
                  <Col span={12}>
                    <SelectField
                      isRequired={true}
                      fieldname="tuesday"
                      label="Tuesday"
                      control={control}
                      iProps={{ placeholder: "Select day status" }}
                      rules={{ required: "Please select day status" }}
                      initValue=""
                      setValue={setValue}
                      valueGot= {getSelectedDay('tuesday')}
                      selectOption={datValues.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      validate={errors.tuesday && "error"}
                      validMessage={errors.tuesday && errors.tuesday.message}
                    />
                  </Col>
                  <Col span={12}>
                    <SelectField
                      isRequired={true}
                      fieldname="wednesday"
                      label="Wednesday"
                      control={control}
                      iProps={{ placeholder: "Select day status" }}
                      rules={{ required: "Please select day status" }}
                      initValue=""
                      setValue={setValue}
                      valueGot= {getSelectedDay('wednesday')}
                      selectOption={datValues.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      validate={errors.wednesday && "error"}
                      validMessage={errors.wednesday && errors.wednesday.message}
                    />
                  </Col>
                  <Col span={12}>
                    <SelectField
                      isRequired={true}
                      fieldname="thursday"
                      label="Thursday"
                      control={control}
                      iProps={{ placeholder: "Select day status" }}
                      rules={{ required: "Please select day status" }}
                      initValue=""
                      setValue={setValue}
                      valueGot= {getSelectedDay('thursday')}
                      selectOption={datValues.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      validate={errors.thursday && "error"}
                      validMessage={errors.thursday && errors.thursday.message}
                    />
                  </Col>
                  <Col span={12}>
                    <SelectField
                      isRequired={true}
                      fieldname="friday"
                      label="Friday"
                      control={control}
                      iProps={{ placeholder: "Select day status" }}
                      rules={{ required: "Please select day status" }}
                      initValue=""
                      setValue={setValue}
                      valueGot= {getSelectedDay('friday')}
                      selectOption={datValues.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      validate={errors.friday && "error"}
                      validMessage={errors.friday && errors.friday.message}
                    />
                  </Col>
                  <Col span={12}>
                    <SelectField
                      isRequired={true}
                      fieldname="saturday"
                      label="Saturday"
                      control={control}
                      iProps={{ placeholder: "Select day status" }}
                      rules={{ required: "Please select day status" }}
                      initValue=""
                      setValue={setValue}
                      valueGot= {getSelectedDay('saturday')}
                      selectOption={datValues.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      validate={errors.saturday && "error"}
                      validMessage={errors.saturday && errors.saturday.message}
                    />
                  </Col> */}
            </Row>
            <Row gutter={24} justify="space-between">
              <Col>
                <Button
                  className="ag-gray-button-outline"
                  onClick={() => {
                    reset({ defaultValues });
                    props.setVisible(false);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button className="ag-green-button" htmlType="submit">
                  Update
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          isError && (
            <>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Space
                    size={24}
                    className="w-100 text-center"
                    direction="vertical"
                  >
                    <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                    <Paragraph className="ag-error fontWeight600 mb-0">
                      {errorMessage}
                    </Paragraph>
                  </Space>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Button
                    className="ag-gray-button-outline"
                    htmlType="button"
                    onClick={() => {
                      setSubmited(false);
                      setisError(false);
                    }}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </>
          )
        )}
      </Form>
    </>
  );
};

export default EditTechnicianSchedule;
