import * as React from "react";
import {
  Row,
  Col,
  Space,
  Typography,
  Form,
  Select,
  Divider,
  Button,
  Input,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { FilterIcon } from "../../atoms/Icons";
import Icon from "@ant-design/icons";
import { CSVLink } from "react-csv";

const { Title, Text } = Typography;
const ExportSvg = () => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.97422 7.75527C4.97422 8.10332 4.69121 8.38457 4.34492 8.38457C4.00039 8.38457 3.71563 8.10332 3.71387 7.75703V4.17461C3.71387 3.30098 4.42402 2.59082 5.29766 2.59082H15.2662C16.1398 2.59082 16.85 3.30098 16.85 4.17285V14.4947C16.85 15.3684 16.1398 16.0785 15.2662 16.0785H10.7908C10.4428 16.0785 10.1615 15.7955 10.1615 15.4492C10.1615 15.1012 10.4445 14.8199 10.7908 14.8199H15.268C15.449 14.8199 15.5949 14.674 15.5949 14.493V4.17285C15.5949 3.9918 15.449 3.8459 15.268 3.8459H5.30117C5.12012 3.8459 4.97422 3.9918 4.97422 4.17285V7.75527ZM11.8753 9.69063C11.8559 9.81543 11.6731 10.9387 10.9155 12.0795C10.2563 13.0709 9.00829 14.2838 6.79872 14.4684V16.0662C6.79872 16.3176 6.64931 16.5461 6.41904 16.6445C6.18876 16.743 5.92157 16.6973 5.73876 16.5215L2.39892 13.3434C2.26884 13.2186 2.19853 13.0445 2.20556 12.8652C2.20907 12.6877 2.29169 12.5207 2.4288 12.4029L5.7704 9.61855C5.88115 9.52715 6.02353 9.47441 6.17118 9.47441C6.26083 9.47441 6.354 9.49375 6.43661 9.53418C6.6581 9.63789 6.80048 9.86113 6.80048 10.1037V12.0479C6.80048 12.3959 6.51747 12.6771 6.17118 12.6771C5.82314 12.6771 5.54189 12.3941 5.54189 12.0479V11.4467L3.77353 12.9197L5.54013 14.6002V13.8602C5.54013 13.5121 5.82314 13.2309 6.16943 13.2309C6.18444 13.2309 6.20202 13.2321 6.22216 13.2336C6.2256 13.2339 6.22912 13.2341 6.23271 13.2344C7.81825 13.2291 9.03466 12.6156 9.84853 11.4133C10.4655 10.4975 10.6255 9.54824 10.6308 9.50605C10.6765 9.20371 10.9436 8.97168 11.253 8.97168C11.2829 8.97168 11.3145 8.97344 11.3427 8.97871C11.6837 9.02617 11.9227 9.34609 11.8753 9.69063Z"
      fill="black"
    />
  </svg>
);
const Export = (props) => <Icon component={ExportSvg} {...props} />;

export default (props) => {
  const {
    heading,
    total,
    exportAction,
    filterAction,
    exportData,
    exportDataHeader,
    addNewAction,
    handleShow,
    showOption,
    handleView,
    viewOption,
    search,
    viewOptionLabel,
    searchPlaceholder,
  } = props;

  function handleChange(value) {
    console.log(value);
  }

  return (
    <Row gutter={[24, 24]}>
      <Col flex="auto">
        <Space size={8} className="h-100">
          {/* {headingOptions.length > 1 ?
            <Select
            bordered={false}
            labelInValue
            onChange={headingAction}
            size="large"
            >
              <Select.Option value="all">All Request</Select.Option>
              <Select.Option value="selected">Selected</Select.Option>
            </Select>
            :
          <Title level={2} className="custom-title32">{headingOptions[0]}</Title>
          } */}
          <Title
            level={2}
            className={`custom-title32 ${!total && "no-border"}`}
          >
            {heading}
          </Title>
          {total && (
            <Title level={5} className="mb-0">
              {total} Total
            </Title>
          )}
        </Space>
      </Col>
      {exportAction && (
        <Col>
          <Button
            size="large"
            icon={<Export style={{ fontSize: 25, marginRight: 10 }} />}
            onClick={exportAction}
          >
            <CSVLink
              style={{ color: "#3c3c4399" }}
              asyncOnClick={true}
              headers={exportDataHeader}
              data={exportData}
              target="_blank"
            >
              Export
            </CSVLink>
          </Button>
        </Col>
      )}
      {/* {filterAction &&
        <Col>
          <Button size="large" icon={<FilterIcon />} onClick={filterAction}>Filters</Button>
        </Col>
      } */}
      {addNewAction && (
        <Col>
          <Button size="large" type="primary" onClick={addNewAction}>
            Add New <PlusOutlined />
          </Button>
        </Col>
      )}

      <Col span={24}>
        <Form className="ag-filter-bar" name="property_filter" layout="inline">
          {showOption && showOption.length && (
            <Form.Item name="show" label="Show" initialValue={{ value: "All" }}>
              <Select
                bordered={false}
                labelInValue
                onChange={handleShow}
                size="large"
                options={showOption}
              />
            </Form.Item>
          )}
          {viewOption && viewOption.length && (
            <Form.Item
              name="view"
              label={viewOptionLabel}
              initialValue={{ value: "All" }}
            >
              <Select
                bordered={false}
                labelInValue
                onChange={handleView}
                size="large"
                options={viewOption}
              />
            </Form.Item>
          )}
          {search && (
            <Form.Item>
              <Input.Search
                bordered={false}
                placeholder={searchPlaceholder ? searchPlaceholder : "Search"}
                prefix={<SearchOutlined />}
                size="large"
                className="searchBTN"
                onSearch={search}
              />
            </Form.Item>
          )}
        </Form>
      </Col>
      <Divider className="mt-0 ag-border-cold9" />
    </Row>
  );
};
