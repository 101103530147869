import * as action_types from "./constants";

const initialState: any = {
  assignedData: [],
  ticketData: {},
  ticketsData: {},
  technicianTicket: [],
  technicianTicket2: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_ASSIGNED_LIST:
      return { ...state, assignedData: data };
    case action_types.GET_SEGREGATED_COUNT:
      return { ...state, ticketData: data };
    case action_types.GET_TICKET_STATUS:
      return { ...state, ticketsData: data };
    case action_types.GET_TICKET_TECHNICIAN:
      return { ...state, technicianTicket: data };
    case action_types.GET_TICKET_TECHNICIAN2:
      return { ...state, technicianTicket2: data };
    default:
      return state;
  }
};
