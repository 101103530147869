import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { maintenanceService } from "../../../../../configs/constants";

const url = `${maintenanceService}/maintenance/fetchTicketsCount`;

export const getTypeCount = (year) => {
  return async (dispatch) => {
    try {
      let urlFilter = `${url}?year=${year}`;
      const {
        data: { result },
      } = await axios.get(urlFilter);
      dispatch({
        type: action_types.GET_TICKET_TYPE_COUNT,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
