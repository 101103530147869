import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Divider,
  Space,
  message,
  Typography,
  Spin,
} from "antd";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getAllDayEntries, getTechnicainDrop } from "./ducks/actions";
import {
  SelectField,
  DateField,
  TimeField,
  InputField,
} from "../../../../../atoms/FormElement";
import axios from "../../../../../../utils/axiosInceptor";
import { maintenanceService } from "../../../../../../configs/constants";
import moment from "moment";

const techChoice = [
  {
    label: "One Technician",
    value: "Single",
  },
  {
    label: "Multiple Technician",
    value: "Multiple",
  },
];

const { Paragraph } = Typography;

const TechnicianSchedule = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [option, setOption] = useState(null);
  const [selected, setSelected] = useState(false);
  const [technicians, setTechnicians] = useState([]);
  const [technicianData, setTechnicianData] = useState();
  const dispatch = useDispatch();

  const { control, handleSubmit, errors, reset, watch, setValue } = useForm();

  const onBack = () => {
    setOption(null);
    setSelected(false);
  };

  let checkTech = watch("technician2");

  const datValues = useSelector((state) => state.rosterReducer.getAllDatStatus);
  // const techniciansData = useSelector(
  //   (state) => state.rosterReducer.getTechnicians,
  // );

  const techniciansData = useSelector(
    (state) => state.rosterReducer.technicianList,
  );

  useEffect(() => {
    dispatch(getAllDayEntries());
    dispatch(getTechnicainDrop());
  }, []);

  useEffect(() => {
    if (checkTech) {
      let em = techniciansData.filter(
        (item) => item.party_id === checkTech.value,
      );
      checkTech && setValue("email_address", em[0].email);
    }
  }, [checkTech]);

  useEffect(() => {
    if (techniciansData) {
      setTechnicianData(
        techniciansData.map((item) => ({
          value: item.party_id,
          label: item.name,
        })),
      );
    }
  }, [techniciansData]);

  const onFirstForm = (values) => {
    option === "Multiple" && setTechnicians(values.technician);
    setSelected(true);
  };

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    let party_ids =
      option === "Multiple"
        ? technicians.map((item) => item.value)
        : [values.technician2.value];
    let postjson = {
      starting_time: values.starting_time,
      ending_time: values.ending_time,
      start_date: values.start_date,
      end_date: values.end_date,
      party_ids,
      schedule_entries: [
        {
          day: "Friday",
          status: values.friday.label,
        },
        {
          day: "Saturday",
          status: values.saturday.label,
        },
        {
          day: "Sunday",
          status: values.sunday.label,
        },
        {
          day: "Monday",
          status: values.monday.label,
        },
        {
          day: "Tuesday",
          status: values.tuesday.label,
        },
        {
          day: "Wednesday",
          status: values.wednesday.label,
        },
        {
          day: "Thursday",
          status: values.thursday.label,
        },
      ],
    };

    const url = `${maintenanceService}/maintenance/createDutyRoster`;
    try {
      await axios.post(url, postjson);
      setLoader(false);
      setSubmited(false);
      message.success(`Technician Schedule Added`);
      reset();
      onBack();
      props.onSubmit();
    } catch (e) {
      const { response } = e;
      setLoader(false);
      setisError(true);
      message.error(`Technician Schedule Added`);
      setErrorMessage(response?.data?.message);
    }
  };

  // const onChangeTechnicians = (value)=>{
  //   console.log(techniciansData,'techniciansDatatechniciansData')
  //   let em = techniciansData.filter(item => item.Party.id === value?.value)
  //   console.log(em[0]?.Party,'ememememememem')
  //   checkTech && setValue("email_address", em[0]?.Party?.PartyContactMechanismEmail[0]?.ContactMechanism?.ElectronicAddress?.email)
  // }

  return (
    <>
      {!selected ? (
        <Form layout="vertical" onFinish={handleSubmit(onFirstForm)}>
          <Row gutter={24}>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="choice"
                label="Name"
                control={control}
                rules={{ required: "Please select ticket type" }}
                onChange={(e) => setOption(e.value)}
                selectOption={techChoice}
                iProps={{ placeholder: "Select one" }}
                validate={errors.choice && "error"}
                validMessage={errors.choice && errors.choice.message}
                initValue={techChoice[0]}
              />
            </Col>
            {option === "Multiple" && (
              <Col span={12}>
                <SelectField
                  isRequired={true}
                  fieldname="technician"
                  label="Add technicians"
                  control={control}
                  iProps={{ placeholder: "Select Technician", isMulti: true }}
                  rules={{ required: "Please select technicians" }}
                  initValue=""
                  selectOption={technicianData}
                  validate={errors.technician && "error"}
                  validMessage={errors.technician && errors.technician.message}
                />
              </Col>
            )}
          </Row>
          <Divider className="no-border m-1" />
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-gray-button-outline"
                onClick={props.onCancel}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
          {loader && !isError && (
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <Spin size="large" className="ag-fontSize32" />
                  {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
                </Space>
              </Col>
            </Row>
          )}
          {!submited ? (
            <>
              <Row gutter={24}>
                {option != "Multiple" && (
                  <>
                    <Col span={12}>
                      <SelectField
                        isRequired={true}
                        fieldname="technician2"
                        label="Add technician"
                        control={control}
                        iProps={{
                          placeholder: "Select Technician",
                        }}
                        rules={{
                          required: "Please select technicians",
                        }}
                        initValue=""
                        selectOption={technicianData}
                        validate={errors.technician2 && "error"}
                        validMessage={
                          errors.technician2 && errors.technician2.message
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        fieldname="email_address"
                        label="Email Address"
                        control={control}
                        iProps={{ readOnly: true, bordered: false }}
                      />
                    </Col>
                  </>
                )}
                <Col span={12}>
                  <DateField
                    isRequired={true}
                    fieldname="start_date"
                    label="Date From"
                    control={control}
                    iProps={{ placeholder: "DD-MM-YYYY", format: "DD-MM-YYYY" }}
                    rules={{
                      required: "Please enter from date",
                      setValueAs: (value) => moment(value).format("YYYY-MM-DD"),
                    }}
                    initValue=""
                    validate={errors.start_date && "error"}
                    validMessage={
                      errors.start_date && errors.start_date.message
                    }
                  />
                </Col>
                <Col span={12}>
                  <DateField
                    isRequired={true}
                    fieldname="end_date"
                    label="Date To"
                    control={control}
                    iProps={{ placeholder: "DD-MM-YYYY", format: "DD-MM-YYYY" }}
                    rules={{
                      required: "Please enter to date",
                      setValueAs: (value) => moment(value).format("YYYY-MM-DD"),
                    }}
                    initValue=""
                    validate={errors.end_date && "error"}
                    validMessage={errors.end_date && errors.end_date.message}
                  />
                </Col>
                <Col span={12}>
                  <TimeField
                    isRequired={true}
                    fieldname="starting_time"
                    label="Shift starting Time"
                    control={control}
                    rules={{
                      required: "Please enter start time",
                      setValueAs: (value) => moment(value).format("HH:mm"),
                    }}
                    initValue=""
                    iProps={{ format: "HH:mm" }}
                    validate={errors.starting_time && "error"}
                    validMessage={
                      errors.starting_time && errors.starting_time.message
                    }
                  />
                </Col>
                <Col span={12}>
                  <TimeField
                    isRequired={true}
                    fieldname="ending_time"
                    label="Shift end Time"
                    control={control}
                    rules={{
                      required: "Please enter end time",
                      setValueAs: (value) => moment(value).format("HH:mm"),
                    }}
                    initValue=""
                    iProps={{ format: "HH:mm" }}
                    validate={errors.ending_time && "error"}
                    validMessage={
                      errors.ending_time && errors.ending_time.message
                    }
                  />
                </Col>
                <Col span={12}>
                  <SelectField
                    isRequired={true}
                    fieldname="sunday"
                    label="Sunday"
                    control={control}
                    iProps={{ placeholder: "Select day status" }}
                    rules={{ required: "Please select day status" }}
                    initValue=""
                    selectOption={datValues.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    validate={errors.sunday && "error"}
                    validMessage={errors.sunday && errors.sunday.message}
                  />
                </Col>
                <Col span={12}>
                  <SelectField
                    isRequired={true}
                    fieldname="monday"
                    label="Monday"
                    control={control}
                    iProps={{ placeholder: "Select day status" }}
                    rules={{ required: "Please select day status" }}
                    initValue=""
                    selectOption={datValues.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    validate={errors.monday && "error"}
                    validMessage={errors.monday && errors.monday.message}
                  />
                </Col>
                <Col span={12}>
                  <SelectField
                    isRequired={true}
                    fieldname="tuesday"
                    label="Tuesday"
                    control={control}
                    iProps={{ placeholder: "Select day status" }}
                    rules={{ required: "Please select day status" }}
                    initValue=""
                    selectOption={datValues.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    validate={errors.tuesday && "error"}
                    validMessage={errors.tuesday && errors.tuesday.message}
                  />
                </Col>
                <Col span={12}>
                  <SelectField
                    isRequired={true}
                    fieldname="wednesday"
                    label="Wednesday"
                    control={control}
                    iProps={{ placeholder: "Select day status" }}
                    rules={{ required: "Please select day status" }}
                    initValue=""
                    selectOption={datValues.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    validate={errors.wednesday && "error"}
                    validMessage={errors.wednesday && errors.wednesday.message}
                  />
                </Col>
                <Col span={12}>
                  <SelectField
                    isRequired={true}
                    fieldname="thursday"
                    label="Thursday"
                    control={control}
                    iProps={{ placeholder: "Select day status" }}
                    rules={{ required: "Please select day status" }}
                    initValue=""
                    selectOption={datValues.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    validate={errors.thursday && "error"}
                    validMessage={errors.thursday && errors.thursday.message}
                  />
                </Col>
                <Col span={12}>
                  <SelectField
                    isRequired={true}
                    fieldname="friday"
                    label="Friday"
                    control={control}
                    iProps={{ placeholder: "Select day status" }}
                    rules={{ required: "Please select day status" }}
                    initValue=""
                    selectOption={datValues.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    validate={errors.friday && "error"}
                    validMessage={errors.friday && errors.friday.message}
                  />
                </Col>
                <Col span={12}>
                  <SelectField
                    isRequired={true}
                    fieldname="saturday"
                    label="Saturday"
                    control={control}
                    iProps={{ placeholder: "Select day status" }}
                    rules={{ required: "Please select day status" }}
                    initValue=""
                    selectOption={datValues.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    validate={errors.saturday && "error"}
                    validMessage={errors.saturday && errors.saturday.message}
                  />
                </Col>
              </Row>
              <Row gutter={24} justify="space-between">
                <Col>
                  <Button className="ag-gray-button-outline" onClick={onBack}>
                    Back
                  </Button>
                </Col>
                <Col>
                  <Button className="ag-green-button" htmlType="submit">
                    Add
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            isError && (
              <>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Space
                      size={24}
                      className="w-100 text-center"
                      direction="vertical"
                    >
                      <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                      <Paragraph className="ag-error fontWeight600 mb-0">
                        {errorMessage}
                      </Paragraph>
                    </Space>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Button
                      className="ag-gray-button-outline"
                      htmlType="button"
                      onClick={() => {
                        setSubmited(false);
                        setisError(false);
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </>
            )
          )}
        </Form>
      )}
    </>
  );
};

export default TechnicianSchedule;
