import Axix from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  maintenanceService,
  authService,
  leaseService,
  ticketBaseUrl,
} from "../../../../../configs/constants";
import LocalStorageService from "../../../../../utils/localStorageService";

const ticketsUrlNew = `${ticketBaseUrl}/facilityManager/getTickets?`;
const singleticketsUrlNew = `${ticketBaseUrl}/facilityManager/getTicket`;

const ticketsTypeUrl = `${maintenanceService}/facilityManager/fetchAllTicketCategoryRoots`;
const ticketsSubTypeUrl = `${maintenanceService}/facilityManager/fetchTicketCategoryChildren`;
// const ticketsUrl = `${maintenanceService}/facilityManager/getTickets`;

const ticketsCountUrl = `${maintenanceService}/facilityManager/getTicketsCount`;
const propertyUrl = `${maintenanceService}/facilityManager/fetchPropertyNames`;
const techUrl = `${maintenanceService}/maintenance/technician/eligibleTechnician`;
const deviceIdUrl = `${authService}/user/device-token`;
const presignedImageUrl = `${leaseService}/leads/download-signed-url`;
const ticketsStatusTypeUrl = `${maintenanceService}/facilityManager/getCaseStatusType`;

export const getTicketType = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(ticketsTypeUrl);
      dispatch({
        type: action_types.TICKETS_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTicketSubType = (param) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.post(ticketsSubTypeUrl, { category_id: param });
      dispatch({
        type: action_types.TICKETS_SUB_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getNewTicket = (token, page, param = {}) => {
  return async (dispatch) => {
    try {
      let data = {};

      await fetch(
        ticketsUrlNew +
          new URLSearchParams({
            partyId: LocalStorageService.getPartyId(),
            status: "new",
            page: page,
            ...param,
          }),
        {
          method: "GET",
          headers: {
            Authorization: `${token?.token}`,
          },
        },
      )
        .then((response) => response.json())
        .then((rest) => {
          data = rest.result;
        });
      dispatch({
        type: action_types.TICKETS_NEW,
        data: data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getInprogressTicket = (token, page, param = {}) => {
  return async (dispatch) => {
    try {
      let data = {};

      await fetch(
        ticketsUrlNew +
          new URLSearchParams({
            partyId: LocalStorageService.getPartyId(),
            status: "inProgress",
            page: page,
            ...param,
          }),
        {
          method: "GET",
          headers: {
            Authorization: `${token?.token}`,
          },
        },
      )
        .then((response) => response.json())
        .then((rest) => {
          data = rest.result;
        });
      dispatch({
        type: action_types.TICKETS_PROGRESS,
        data: data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getClosedTicket = (token, page, param = {}) => {
  return async (dispatch) => {
    try {
      let data = {};

      await fetch(
        ticketsUrlNew +
          new URLSearchParams({
            partyId: LocalStorageService.getPartyId(),
            status: "completed",
            page: page,
            ...param,
          }),
        {
          method: "GET",
          headers: {
            Authorization: `${token?.token}`,
          },
        },
      )
        .then((response) => response.json())
        .then((rest) => {
          data = rest.result;
        });
      dispatch({
        type: action_types.TICKETS_CLOSED,
        data: data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

// export const getNewCount = (param = {}) => {
//   return async (dispatch) => {
//     try {
//       const {
//         data: { result },
//       } = await Axix.get(`${ticketsCountUrl}?status=new`, { params: param });

//       dispatch({
//         type: action_types.TICKETS_COUNT_NEW,
//         data: result,
//       });
//     } catch (e) {
//       console.log("Err", e);
//     }
//   };
// };

export const getInprogressCount = (param = {}) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${ticketsCountUrl}?status=inProgress`, {
        params: param,
      });
      dispatch({
        type: action_types.TICKETS_COUNT_PROGRESS,
        data: result,
      });
    } catch (e) {
      console.log("Errr", e);
    }
  };
};

export const getClosedCount = (param = {}) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${ticketsCountUrl}?status=completed`, {
        params: param,
      });
      dispatch({
        type: action_types.TICKETS_COUNT_CLOSED,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getNewTicketStatusType = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${ticketsStatusTypeUrl}?status=new`);
      dispatch({
        type: action_types.TICKETS_NEW_STATUS_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getProgressTicketStatusType = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${ticketsStatusTypeUrl}?status=inProgress`);
      dispatch({
        type: action_types.TICKETS_PROGRESS_STATUS_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getClosedTicketStatusType = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(`${ticketsStatusTypeUrl}?status=completed`);
      dispatch({
        type: action_types.TICKETS_CLOSED_STATUS_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getPropertyName = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(propertyUrl);
      dispatch({
        type: action_types.PROPERTY_NAME,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTechnicians = (body) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.post(techUrl, body);
      dispatch({
        type: action_types.ELIGIBLE_TECHNICIAN,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const resetTechnician = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.RESET_TECHNICIAN,
      data: [],
    });
  };
};

export const getDeviceToken = () => {
  return async (dispatch) => {
    try {
      const body = {
        token: LocalStorageService.getAccessToken(),
      };
      const {
        data: { result },
      } = await Axix.post(deviceIdUrl, body);
      dispatch({
        type: action_types.GET_DEVICEID_TOKEN,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

// export const getPresignedImage = (mediaArray) => {
//   return async (dispatch) => {
//     try {
//       const result = await apiIterator(mediaArray);
//       console.log('I AM A RESULTER', result);
//       dispatch({
//         type: action_types.PRESIGNED_IMAGE,
//         data: result,
//       });
//     } catch (e) {
//       console.log("Err", e);
//     }
//   };
// };

export const getPresignedImage = async (mediaArray) => {
  const mediaUrls = [];
  for (let i of mediaArray) {
    const postData = { url: i };
    await Axix.post(presignedImageUrl, postData)
      .then((x) => {
        mediaUrls.push(x.data?.result?.imageUrl);
      })
      .catch((e) => {
        const { response } = e;
        console.log("Error", response.message);
      });
  }
  return mediaUrls;
};

export const getTicketReviews = (param) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(
        `${maintenanceService}/facilityManager/getTicketReviews`,
        { params: { caseId: param } },
      );
      dispatch({
        type: action_types.TICKETS_REVIEWS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTicketByID = (id) => {
  return async (dispatch) => {
    try {
      let data = {};
      await fetch(
        `${singleticketsUrlNew}/${id}?` +
          new URLSearchParams({
            partyId: LocalStorageService.getPartyId(),
          }),
        {
          method: "GET",
          headers: {
            Authorization: `${LocalStorageService.getTicketToken()}`,
          },
        },
      )
        .then((response) => response.json())
        .then((rest) => {
          data = rest.result;
        });
      dispatch({
        type: action_types.TICKET_BY_ID,
        data: data,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTicketsExport = (param = {}) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await Axix.get(
        `${maintenanceService}/facilityManager/getTicketsExportData`,
        { params: param },
      );
      dispatch({
        type: action_types.TICKETS_EXPORT,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getPropertyUnits = (property_id) => {
  return async (dispatch) => {
    try {
      const {
        data: {
          result: { rows },
        },
      } = await Axix.get(
        `${maintenanceService}/facilityManager/fetchPropertyUnits/${property_id}`,
      );
      dispatch({
        type: action_types.GET_PROPERTY_UNITS,
        data: rows,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const emptyTickets = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.EMPTY_TICKETS,
      data: null,
    });
  };
};
//k
