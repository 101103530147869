import axios from '../../../../../utils/axiosInceptor'
import * as action_types from "./constants";
import { maintenanceService } from "../../../../../configs/constants";

const areaUrl = `${maintenanceService}/maintenance/getAllAreas`;
const areaStatuUrl = `${maintenanceService}/maintenance/getAllZoneStatusTypes`;
const zonesUrl = `${maintenanceService}/maintenance/getAllZonesWithBuildings`;
const unAssignedBuildingUrl = `${maintenanceService}/facilityManager/fetchPropertyNames`;


export const getAllAreas = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(areaUrl);
      dispatch({
        type: action_types.GET_ALL_AREAS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getAreaStatus = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(areaStatuUrl);
      dispatch({
        type: action_types.GET_AREA_STATUS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};


export const getListZones = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(zonesUrl);
      dispatch({
        type: action_types.GET_ALL_ZONES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getBuilding = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(unAssignedBuildingUrl);
      dispatch({
        type: action_types.GET_ALL_BUILDING,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};