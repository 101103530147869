import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Layout,
  Avatar,
  Dropdown,
  Menu,
  Image,
  Space,
  Button,
  Badge,
  Typography,
} from "antd";
import {
  UserOutlined,
  SearchOutlined,
  BellOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { getNotifications, getNewNotifications } from "./duck/actions";
import storeLogo from "../../../assets/img/eqari-logo.svg";
import NotificationPanel from "../NotificationPanel";
import { deleteCookie } from "../../../utils/helper";
import { Link } from "react-router-dom";
import { onLogout } from "../../modules/Login/duck/actions";

const { Header } = Layout;
const { Text } = Typography;

let cachedNotificationLength = 0;

const HeaderBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [seen, setSeen] = useState(true);
  const [notifiyDropDown, setNotifiyDropDown] = useState(false);
  const [visible, setVisible] = useState(false);

  let notificationInterval = null;

  const logoutHandler = () => {
    window.localStorage.clear();
    deleteCookie("landlordUser");
    dispatch(onLogout());
    history.push("/login");
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
    console.log("flag", flag);
  };

  // const notificationsData = useSelector(
  //   (state) => state.header.getNotification,
  // );

  // const newNotificationsData = useSelector(
  //   (state) => state.header.getNewNotifications,
  // );

  // useEffect(() => {
  //   dispatch(getNotifications());
  //   dispatch(getNewNotifications());
  // }, []);

  // useEffect(() => {
  //   if (notificationsData.length) {
  //     cachedNotificationLength = notificationsData.length;
  //     setNotifications(notificationsData);
  //   }
  //   if (notificationsData.length > cachedNotificationLength) {
  //     setSeen(true);
  //   }
  // }, [notificationsData]);

  // useEffect(() => {
  //   notificationInterval = setInterval(() => {
  //     dispatch(getNotifications());
  //     dispatch(getNewNotifications());
  //   }, 1000 * 5);
  //   return () => {
  //     clearInterval(notificationInterval);
  //   };
  // }, []);

  const menu = (
    <Menu>
      <Menu.Item>
        <Button
          onClick={logoutHandler}
          type="link"
          className="btn-link"
          icon={<LogoutOutlined />}
        >
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  );

  const Notification: any = NotificationPanel;

  return (
    <Header className="site-header">
      <Row>
        <Col flex={200}>
          <Image
            src={storeLogo}
            preview={false}
            width={150}
            alt="EQARCOM logo"
            style={{ marginTop: 5 }}
          />
        </Col>
        <Col flex="auto">
          <Space size={12}>
            {/* <Space size={0}>
              <Dropdown
                overlay={
                  <Notification
                    handleVisibleChange={handleVisibleChange}
                    setNotifiyDropDown={setNotifiyDropDown}
                    notificationsData={notifications}
                    newNotificationsData={newNotificationsData}
                  />
                }
                placement="bottomRight"
                trigger={["click"]}
                arrow
                onVisibleChange={handleVisibleChange}
                visible={visible}
              >
                <Button
                  type="link"
                  className="headerIcon-btn"
                  size="large"
                  onClick={() => {
                    setSeen(false);
                    setNotifiyDropDown(true);
                  }}
                >
                  <Badge {...(newNotificationsData.length && { dot: true })}>
                    <BellOutlined />
                  </Badge>
                </Button>
              </Dropdown>
            </Space> */}
            <Dropdown overlay={menu} placement="bottomRight">
              <Space>
                <Avatar size={24} icon={<UserOutlined />} />
                <Text>
                  {
                    JSON.parse(window.localStorage.getItem("login-response"))
                      ?.data?.firstName
                  }
                </Text>
              </Space>
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderBar;
