export const TICKETS_COUNT_PROGRESS = "TICKETS_COUNT_PROGRESS";
export const TICKETS_COUNT_CLOSED = "TICKETS_COUNT_CLOSED";
export const TICKETS_COUNT_NEW = "TICKETS_COUNT_NEW";
export const TICKETS_PROGRESS = "TICKETS_PROGRESS";
export const TICKETS_CLOSED = "TICKETS_CLOSED";
export const TICKETS_NEW = "TICKETS_NEW";
export const TICKETS_TYPE = "TICKETS_TYPE";
export const TICKETS_SUB_TYPE = "TICKETS_SUB_TYPE";
export const PROPERTY_NAME = "PROPERTY_NAME";
export const ELIGIBLE_TECHNICIAN = "ELIGIBLE_TECHNICIAN";
export const GET_DEVICEID_TOKEN = "GET_DEVICEID_TOKEN";
export const PRESIGNED_IMAGE = "PRESIGNED_IMAGE";
export const RESET_TECHNICIAN = "RESET_TECHNICIAN";
export const TICKETS_NEW_STATUS_TYPE = "TICKETS_NEW_STATUS_TYPE";
export const TICKETS_CLOSED_STATUS_TYPE = "TICKETS_CLOSED_STATUS_TYPE";
export const TICKETS_PROGRESS_STATUS_TYPE = "TICKETS_PROGRESS_STATUS_TYPE";
export const TICKETS_REVIEWS = "TICKETS_REVIEWS";
export const TICKET_BY_ID = "TICKET_BY_ID";
export const TICKETS_EXPORT = "TICKETS_EXPORT";
export const GET_PROPERTY_UNITS = "GET_PROPERTY_UNITS";
export const EMPTY_TICKETS = "EMPTY_TICKETS";
