import { LOGOUT_INIT, LOGOUT_SUCCESS, LOGOUT_FAILURE, GET_NOTIFICATIONS, GET_NEW_NOTIFICATIONS } from "./constants";

import { TOKEN_KEY } from "../../../../configs";
import { removeFromLocal } from "../../../../utils/cache";

const initialState = {
  id: "",
  isLoading: false,
  getNotification: [],
  getNewNotifications: [],
};

const Header = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case LOGOUT_INIT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case LOGOUT_SUCCESS: {
      removeFromLocal(TOKEN_KEY);
      return {
        ...state,
        isLoading: false,
      };
    }

    case LOGOUT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_NOTIFICATIONS: {
      return {
        ...state,
        getNotification: action.data,
      };
    }
    case GET_NEW_NOTIFICATIONS:
      return { ...state, getNewNotifications: action.data }

    default:
      return state;
  }
};
export default Header;
