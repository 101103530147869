import * as action_types from "./constants";

const initialState: any = {
  typeCount: {},
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_TICKET_TYPE_COUNT:
      return { ...state, typeCount: data };
    default:
      return state;
  }
};
