import React, { useState, useEffect } from "react";
import { Row, Col, Typography, message } from "antd";
import { useSelector, useDispatch } from "react-redux";

import Tickets from "../../../molecules/Tickets";
import AddTicket from "./components/AddTicket";
import ViewTicket from "./components/ViewTicket";
import AssignTechnician from "./components/AssignTechnician";

import SectionHeader from "../../../molecules/SectionHeader";
import { Popup } from "../../../atoms/Popup";
import DashboardLayout from "../../../molecules/DashboardLayout";
import BreadCrumb from "../../../atoms/BreadCrumb";

import {
  getNewTicket,
  getInprogressTicket,
  getClosedTicket,
  getPresignedImage,
  getTicketType,
  getNewTicketStatusType,
  getClosedTicketStatusType,
  getProgressTicketStatusType,
  getTicketByID,
  getTicketsExport,
  emptyTickets,
} from "./ducks/actions";
import { getAllTechnicians } from "../Technicians/ducks/actions";
import { getTechnicains } from "../Roster/components/TechnicianSchedule/ducks/actions";
import {
  baseUrl,
  baseUrl2,
  maintenanceService,
} from "../../../../configs/constants";
import axios from "axios";
import { CSVDownload } from "react-csv";
import moment from "moment";
import ActivityLog from "./components/ViewTicket/activityLog";
import RejectTicket from "./components/ViewTicket/rejectTicket";
import ViewTenantDetail from "./components/ViewTicket/ViewTenantDetail";
import { useForm } from "react-hook-form";

const { Title } = Typography;

const showOption = {
  value: "All",
  label: "All Technicians",
};
const categories = {
  value: "All",
  label: "All Categories",
};
const defaultNewStatusType = {
  value: "All",
  label: "All New Tickets",
};
const defaultInProgressStatusType = {
  value: "All",
  label: "All In Progress Tickets",
};
const defaultCompletedStatusType = {
  value: "All",
  label: "All Closed Tickets",
};
const breadcrumbList: any = [
  {
    label: "Maintenance",
    route: "/tickets",
  },
  {
    label: "Tickets",
    route: "/tickets",
  },
];
const showNewTicketStatusOption = [
  {
    value: "All",
    label: "All New Tickets",
  },
  {
    value: "Assign",
    label: "Assigned Request",
  },
];

const exportDataHeader = [
  { label: "Case Id", key: "case_id" },
  { label: "Status", key: "status" },
  { label: "Initial Assessment", key: "initial_assessment" },
  { label: "Acknowledged", key: "acknowledged" },
  { label: "Reached Barcode Scanned", key: "reached_barcode_scanned" },
  { label: "Inprogress Barcode Scanned", key: "inprogress_barcode_scanned" },
  { label: "Ticket Type Changed", key: "ticket_type_changed" },
  { label: "Issue Resolved", key: "issue_resolved" },
  { label: "Issue Not Resolved", key: "issue_not_resolved" },
  { label: "Technician Forwarded", key: "technician_forwarded" },
  { label: "AG not Responsible", key: "ag_not_responsible" },
  { label: "Reopen Closed", key: "reopen_closed" },
  { label: "Pending Confirmation", key: "pending_confirmation" },
  { label: "Suspended", key: "suspended" },
  { label: "Reject", key: "reject" },
  { label: "Ticket Type Confirm", key: "ticket_type_confirm" },
  { label: "Tenant Status", key: "tenant_status" },
  { label: "Type", key: "type" },
  { label: "SubType", key: "subtype" },
  { label: "Tenant Name", key: "tenant_name" },
  { label: "Technician", key: "technician" },
  { label: "Appartment", key: "appartment" },
  { label: "Recuring", key: "recuring" },
  { label: "Description", key: "description" },
  { label: "Rating", key: "rating" },
  { label: "Review", key: "review" },
  { label: "Date", key: "date" },
];

// [11:28 AM] Akhilesh Jain
//     initial_assessment
// acknowledged
// reached_barcode_scanned
// inprogress_barcode_scanned
// ticket_type_changed
// issue_resolved
// issue_not_resolved
// technician_forwarded
// ag_not_responsible
// reopen_closed
// pending_confirmation
// suspended
// reject
// ticket_type_confirm

const ticketDef: any = {
  new: [],
  inprogress: [],
  closed: [],
};

export default (props) => {
  const dispatch = useDispatch();

  const [ticketNew, setTicketNew] = useState([]);
  const [ticketInProgress, setTicketInProgress] = useState([]);
  const [ticketClosed, setTicketClosed] = useState([]);
  const [countNew, setCountNew] = useState(1);
  const [countProgress, setCountProgress] = useState(1);
  const [countClosed, setCountClosed] = useState(1);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [selectedTicket, setSelectedTickets] = useState();
  const [recurringTicket, setRecurringTicket] = useState();
  const [technicians, setTechnicians] = useState([showOption]);
  const [ticketsType, setTicketType] = useState([categories]);
  const [technicianId, setTechnicianId] = useState();
  const [categoryName, setCategoryName] = useState();
  const [typeSearch, settypeSearch] = useState();
  const [newStatusType, setNewStatusType] = useState([defaultNewStatusType]);
  const [completedStatusType, setCompletedtatusType] = useState([
    defaultCompletedStatusType,
  ]);
  const [progressStatusType, setProgressStatusType] = useState([
    defaultInProgressStatusType,
  ]);
  const [newStatusTypeId, setNewStatusTypeId] = useState();
  const [closedStatusTypeId, setClosedStatusTypeId] = useState();
  const [progressStatusTypeId, setProgressStatusTypeId] = useState();
  const [exportData, setExportData] = useState([]);
  const [singleicketData, setSingleicketData] = useState({});
  const [activityLogTicketData, setActivityLogTicketData] = useState();
  const [rejectTicketData, setRejectTicketData] = useState();
  const [tenantDetailData, setTenantDetailData] = useState();
  const [rejectTicketSubmit, setRejectTicketSubmit] = useState();
  const notifyCaseId = props?.location?.state?.identifier_value;
  const ticketsApiNew = useSelector((state) => state.tickets.ticketsDataNew);

  const ticketsApiProgress = useSelector(
    (state) => state.tickets.ticketsDataProgress,
  );
  const ticketsApiClosed = useSelector(
    (state) => state.tickets.ticketsDataClosed,
  );

  const techniciansData = useSelector(
    (state) => state.rosterReducer.getTechnicians,
  );

  const ticketTypeApi = useSelector((state) => state.tickets.ticketsType);

  const newTicketStatusType = useSelector(
    (state) => state.tickets.newTicketStatusType,
  );
  const closedTicketStatusType = useSelector(
    (state) => state.tickets.closedTicketStatusType,
  );
  const progressTicketStatusType = useSelector(
    (state) => state.tickets.progressTicketStatusType,
  );

  const singleicket = useSelector((state) => state.tickets.getTicketById);

  const ticketExport = useSelector((state) => state.tickets.getTicketExport);

  const getTokenApi = async () => {
    let token: any = localStorage.getItem("token2");
    if (!token) {
      await fetch(`${baseUrl2}/get-token`, {
        method: "POST",
        body: JSON.stringify({
          username: "globalAPIAdmin",
          password: "d4Lkdulqilnvryg6CN6e9hfUJz9ss8pq",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem("token2", JSON.stringify(data.result));
          token = data.result;
        });
    } else {
      token = JSON.parse(token);
    }
    return token;
  };

  useEffect(() => {
    setInterval(() => {
      localStorage.removeItem("token2");
      getTokenApi();
    }, 1000 * 60 * 60);
  }, []);

  const callingApi = async () => {
    dispatch(emptyTickets());
    setTicketNew([]);
    setTicketInProgress([]);
    setTicketClosed([]);
    setCountClosed(1);
    setCountProgress(1);
    setCountNew(1);
    let param = {};
    if (technicianId) {
      param["technicianId"] = technicianId;
    }
    if (categoryName) {
      param["caseCategoryName"] = categoryName;
    }
    if (typeSearch) {
      param["search"] = typeSearch;
    }

    // dispatch(getTicketsExport({ ...param }));
    let tokena = await getTokenApi();
    if (newStatusTypeId) {
      dispatch(
        getNewTicket(tokena, 1, {
          ...param,
          ...{ subStatus: newStatusTypeId },
        }),
      );
    } else {
      dispatch(getNewTicket(tokena, 1, param));
    }
    if (progressStatusTypeId) {
      dispatch(
        getInprogressTicket(tokena, 1, {
          ...param,
          ...{ subStatus: progressStatusTypeId },
        }),
      );
    } else {
      dispatch(getInprogressTicket(tokena, 1, param));
    }
    if (closedStatusTypeId) {
      dispatch(
        getClosedTicket(tokena, 1, {
          ...param,
          ...{ subStatus: closedStatusTypeId },
        }),
      );
    } else {
      dispatch(getClosedTicket(tokena, 1, param));
    }
    // dispatch(getTechnicains());
  };

  useEffect(() => {
    callingApi();
  }, [technicianId, categoryName, typeSearch]);

  useEffect(() => {
    if (rejectTicketSubmit) {
      callingApi();
    }
  }, [rejectTicketSubmit]);

  useEffect(() => {
    if (techniciansData) {
      let data = techniciansData.map((item) => ({
        value: item.party_id,
        label: item.Party.Person.first_name,
      }));
      data.unshift(showOption);

      setTechnicians(data);
    }
  }, [techniciansData]);

  useEffect(() => {
    dispatch(getNewTicketStatusType());
    dispatch(getProgressTicketStatusType());
    dispatch(getClosedTicketStatusType());
    ticketTypeApi.length === 0 && dispatch(getTicketType());
    // dispatch(getTicketsExport());
    return () => {
      dispatch(emptyTickets());
      localStorage.removeItem("token2");
    };
  }, []);

  useEffect(() => {
    if (notifyCaseId) {
      dispatch(getTicketByID(notifyCaseId));
    }
  }, [notifyCaseId]);

  useEffect(() => {
    singleicket && callImages(singleicket, false);
  }, [singleicket]);

  useEffect(() => {
    if (techniciansData) {
      let data = ticketTypeApi.map((item) => ({
        value: item?.ChildCaseCategory?.name,
        label: item?.ChildCaseCategory?.display_name,
      }));
      data.unshift(categories);

      setTicketType(data);
    }
  }, [ticketTypeApi]);

  const statusApi = () => {
    if (newTicketStatusType) {
      let data = newTicketStatusType.map((item) => ({
        value: item.name,
        label: item.display_name,
      }));
      data.unshift(defaultNewStatusType);
      setNewStatusType(data);
    }
    if (closedTicketStatusType) {
      let data = closedTicketStatusType.map((item) => ({
        value: item.name,
        label: item.display_name,
      }));
      data.unshift(defaultCompletedStatusType);
      setCompletedtatusType(data);
    }
    if (progressTicketStatusType) {
      let data = progressTicketStatusType.map((item) => ({
        value: item.name,
        label: item.display_name,
      }));
      data.unshift(defaultInProgressStatusType);
      setProgressStatusType(data);
    }
  };

  useEffect(() => {
    statusApi();
  }, [newTicketStatusType, closedTicketStatusType, progressTicketStatusType]);

  const onSubmit = () => {
    callingApi();
    setVisible(false);
  };

  useEffect(() => {
    if (ticketsApiNew && ticketsApiNew.pageData.length > 0) {
      setTicketNew([...ticketNew, ...ticketsApiNew?.pageData]);
    }
  }, [ticketsApiNew]);

  useEffect(() => {
    if (ticketsApiProgress && ticketsApiProgress.pageData.length > 0) {
      setTicketInProgress([
        ...ticketInProgress,
        ...ticketsApiProgress?.pageData,
      ]);
    }
  }, [ticketsApiProgress]);

  useEffect(() => {
    if (ticketsApiClosed && ticketsApiClosed.pageData.length > 0) {
      setTicketClosed([...ticketClosed, ...ticketsApiClosed?.pageData]);
    }
  }, [ticketsApiClosed]);

  const countUpdate = async (param) => {
    let parami = {};
    if (technicianId) {
      parami["technicianId"] = technicianId;
    }
    if (categoryName) {
      parami["caseCategoryName"] = categoryName;
    }
    if (typeSearch) {
      parami["search"] = typeSearch;
    }
    let tokena = await getTokenApi();
    if (param === "new") {
      if (newStatusTypeId) {
        parami["subStatus"] = newStatusTypeId;
      }
      setCountNew(countNew + 1);
      dispatch(getNewTicket(tokena, countNew + 1, parami));
    } else if (param === "progress") {
      if (progressStatusTypeId) {
        parami["subStatus"] = progressStatusTypeId;
      }
      setCountProgress(countProgress + 1);
      dispatch(getInprogressTicket(tokena, countProgress + 1, parami));
    } else if (param === "closed") {
      if (closedStatusTypeId) {
        parami["subStatus"] = closedStatusTypeId;
      }
      setCountClosed(countClosed + 1);
      dispatch(getClosedTicket(tokena, countClosed + 1, parami));
    }
  };

  const popup = [
    {
      closable: true,
      title: (
        <Title level={3} className="ag-primary mb-0">
          Add a Ticket
        </Title>
      ),
      content: (
        <AddTicket onSubmit={onSubmit} onCancel={() => setVisible(false)} />
      ),
      width: 650,
    },
    {
      title: null,
      content: (
        <ViewTicket
          data={selectedTicket}
          onRecurring={(e) => originalTicket(e)}
          onActivityLog={(e) => activityLogTicket(e)}
          onRejectTicket={(e) => rejectTicketTicket(e)}
          onviewTenantDetails={(e) => viewTeanantDetail(e)}
          onCancel={() => setVisible(false)}
          assignTech={assignTechnician}
          onSubmit={onSubmit}
        />
      ),
      width: 920,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Assign Technician
        </Title>
      ),
      content: (
        <AssignTechnician
          ticket={selectedTicket}
          onBack={() => setTrigger(1)}
          onAssign={onAssign}
        />
      ),
      width: 650,
    },
    {
      title: null,
      content: (
        <ViewTicket
          data={recurringTicket}
          onActivityLog={(e) => activityLogTicket(e)}
          onviewTenantDetails={(e) => viewTeanantDetail(e)}
          onBack={() => {
            setTrigger(1);
            setRecurringTicket(null);
          }}
          onCancel={() => setVisible(false)}
          assignTech={assignTechnician}
        />
      ),
      width: 920,
    },
    {
      title: null,
      content: (
        <ActivityLog
          data={activityLogTicketData}
          onBack={() => {
            if (recurringTicket) {
              setTrigger(3);
            } else {
              setTrigger(1);
            }
            setActivityLogTicketData(null);
          }}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 920,
    },
    {
      title: null,
      content: (
        <RejectTicket
          data={rejectTicketData}
          setRejectTicketSubmit={setRejectTicketSubmit}
          onBack={() => {
            setTrigger(1);
            setRejectTicketData(null);
          }}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 920,
    },
    {
      title: null,
      content: (
        <ViewTenantDetail
          data={tenantDetailData}
          onBack={() => {
            if (recurringTicket) {
              setTrigger(3);
            } else {
              setTrigger(1);
            }
            setTenantDetailData(null);
          }}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 920,
    },
  ];

  const popupProps = {
    closable: false,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  function handleChange(value) {
    console.log(value);
  }
  function handleShowTechnician(value) {
    setTechnicianId(value?.value == "All" ? null : value?.value);
  }
  function handleShowCategory(value) {
    setCategoryName(value?.value == "All" ? null : value?.value);
  }
  const handleNewStatusChange = async (value) => {
    setTicketNew([]);
    let param = {};
    if (technicianId) {
      param["technicianId"] = technicianId;
    }
    if (categoryName) {
      param["caseCategoryName"] = categoryName;
    }
    if (typeSearch) {
      param["search"] = typeSearch;
    }
    if (value !== "All") {
      param["subStatus"] = value;
    }
    setCountNew(1);
    setNewStatusTypeId(value !== "All" ? value : null);
    let tokena = await getTokenApi();
    dispatch(getNewTicket(tokena, 1, param));
  };
  const handleProgressStatusChange = async (value) => {
    setTicketInProgress([]);

    let param = {};
    if (technicianId) {
      param["technicianId"] = technicianId;
    }
    if (categoryName) {
      param["caseCategoryName"] = categoryName;
    }
    if (typeSearch) {
      param["search"] = typeSearch;
    }
    if (value !== "All") {
      param["subStatus"] = value;
    }
    let tokena = await getTokenApi();
    setCountProgress(1);
    setProgressStatusTypeId(value !== "All" ? value : null);
    dispatch(getInprogressTicket(tokena, 1, param));
  };
  const handleCompletedStatusChange = async (value) => {
    setTicketClosed([]);
    let param = {};
    if (technicianId) {
      param["technicianId"] = technicianId;
    }
    if (categoryName) {
      param["caseCategoryName"] = categoryName;
    }
    if (typeSearch) {
      param["search"] = typeSearch;
    }
    if (value !== "All") {
      param["subStatus"] = value;
    }
    let tokena = await getTokenApi();
    setCountClosed(1);
    setClosedStatusTypeId(value !== "All" ? value : null);
    dispatch(getClosedTicket(tokena, 1, param));
  };

  function onTopSearch(e) {
    settypeSearch(e);
  }

  function assignTechnician() {
    setTrigger(2);
  }

  function originalTicket(ticket) {
    callImages(ticket, true);
  }
  function activityLogTicket(ticket) {
    callImages(ticket, "activity");
  }

  function rejectTicketTicket(ticket) {
    callImages(ticket, "rejectTicket");
  }

  function viewTeanantDetail(ticket) {
    callImages(ticket, "tenantDetail");
  }

  function onAssign() {
    setTrigger(1);
  }

  const onClickExport = async () => {
    console.log("--------------------", ticketExport);
    let data = [];
    const statusData = [
      ...newStatusType,
      ...completedStatusType,
      ...progressStatusType,
    ];
    ticketExport?.map((item: any, index) => {
      let status = statusData.find(
        (element) =>
          element.value ==
          item.CaseStatus[item.CaseStatus.length - 1].CaseStatusType
            .ticket_status,
      );
      let initial_assessment = item?.CaseStatus.find(
        (element) =>
          element.CaseStatusType.ticket_status == "initial_assessment",
      );
      let acknowledged = item?.CaseStatus.find(
        (element) => element.CaseStatusType.ticket_status == "acknowledged",
      );
      let reached_barcode_scanned = item?.CaseStatus.find(
        (element) =>
          element.CaseStatusType.ticket_status == "reached_barcode_scanned",
      );
      let inprogress_barcode_scanned = item?.CaseStatus.find(
        (element) =>
          element.CaseStatusType.ticket_status == "inprogress_barcode_scanned",
      );
      let ticket_type_changed = item?.CaseStatus.find(
        (element) =>
          element.CaseStatusType.ticket_status == "ticket_type_changed",
      );
      let issue_resolved = item?.CaseStatus.find(
        (element) => element.CaseStatusType.ticket_status == "issue_resolved",
      );
      let issue_not_resolved = item?.CaseStatus.find(
        (element) =>
          element.CaseStatusType.ticket_status == "issue_not_resolved",
      );
      let technician_forwarded = item?.CaseStatus.find(
        (element) =>
          element.CaseStatusType.ticket_status == "technician_forwarded",
      );
      let ag_not_responsible = item?.CaseStatus.find(
        (element) =>
          element.CaseStatusType.ticket_status == "ag_not_responsible",
      );
      let reopen_closed = item?.CaseStatus.find(
        (element) => element.CaseStatusType.ticket_status == "reopen_closed",
      );
      let pending_confirmation = item?.CaseStatus.find(
        (element) =>
          element.CaseStatusType.ticket_status == "pending_confirmation",
      );
      let suspended = item?.CaseStatus.find(
        (element) => element.CaseStatusType.ticket_status == "suspended",
      );
      let reject = item?.CaseStatus.find(
        (element) => element.CaseStatusType.ticket_status == "reject",
      );
      let ticket_type_confirm = item?.CaseStatus.find(
        (element) =>
          element.CaseStatusType.ticket_status == "ticket_type_confirm",
      );

      const tenantStatus = () => {
        switch (status?.value) {
          case "issue_not_resolved":
            return "Not Solved";
          case "ag_not_responsible":
            return "Not Solved";
          case "issue_resolved":
            return "Solved";
        }
      };

      let type =
        item.CaseCategoryClassification[0]?.CaseCategory?.category_type;
      let stype =
        item.CaseCategorySecondaryClassification[0]?.CaseSubCategory
          ?.category_sub_type;
      let appartment = item.Asset?.property_name;
      let tenant =
        item.CaseRoleTenant?.length > 0
          ? item.CaseRoleTenant[0]?.Party?.name
          : "";
      let technician =
        item.CaseRoleTechnician?.length > 0
          ? item.CaseRoleTechnician[0]?.Party?.name
          : "";
      let recuring = item.ChildCase[0]?.parent_id;
      let tickid = item.CaseStatus[0]?.case_id;
      let description = item.description;
      let date =
        (item.ticket_date ? item.ticket_date : "") +
        " " +
        (item.ticket_start_time
          ? moment(item.ticket_start_time, "hh:mm:ss").format("LT") + " to "
          : "") +
        (item.ticket_end_time
          ? moment(item.ticket_end_time, "hh:mm:ss").format("LT")
          : "");
      let review = item.CaseReview?.review_text;
      let rating = item.CaseReview?.CaseReviewType?.ReviewType?.rating;
      let initial_assessment_data = initial_assessment
        ? moment(initial_assessment?.createdAt).format("LLL")
        : "";
      let acknowledged_data = acknowledged
        ? moment(acknowledged?.createdAt).format("LLL")
        : "";
      let reached_barcode_scanned_data = reached_barcode_scanned
        ? moment(reached_barcode_scanned?.createdAt).format("LLL")
        : "";
      let inprogress_barcode_scanned_data = inprogress_barcode_scanned
        ? moment(inprogress_barcode_scanned?.createdAt).format("LLL")
        : "";
      let ticket_type_changed_data = ticket_type_changed
        ? moment(ticket_type_changed?.createdAt).format("LLL")
        : "";
      let issue_resolved_data = issue_resolved
        ? moment(issue_resolved?.createdAt).format("LLL")
        : "";
      let issue_not_resolved_data = issue_not_resolved
        ? moment(issue_not_resolved?.createdAt).format("LLL")
        : "";
      let technician_forwarded_data = technician_forwarded
        ? moment(technician_forwarded?.createdAt).format("LLL")
        : "";
      let ag_not_responsible_data = ag_not_responsible
        ? moment(ag_not_responsible?.createdAt).format("LLL")
        : "";
      let reopen_closed_data = reopen_closed
        ? moment(reopen_closed?.createdAt).format("LLL")
        : "";
      let pending_confirmation_data = pending_confirmation
        ? moment(pending_confirmation?.createdAt).format("LLL")
        : "";
      let suspended_data = suspended
        ? moment(suspended?.createdAt).format("LLL")
        : "";
      let reject_data = reject ? moment(reject?.createdAt).format("LLL") : "";
      let ticket_type_confirm_data = ticket_type_confirm
        ? moment(ticket_type_confirm?.createdAt).format("LLL")
        : "";

      data.push({
        case_id: tickid,
        status: status?.label,
        initial_assessment: initial_assessment_data,
        acknowledged: acknowledged_data,
        reached_barcode_scanned: reached_barcode_scanned_data,
        inprogress_barcode_scanned: inprogress_barcode_scanned_data,
        ticket_type_changed: ticket_type_changed_data,
        issue_resolved: issue_resolved_data,
        issue_not_resolved: issue_not_resolved_data,
        technician_forwarded: technician_forwarded_data,
        ag_not_responsible: ag_not_responsible_data,
        reopen_closed: reopen_closed_data,
        pending_confirmation: pending_confirmation_data,
        suspended: suspended_data,
        reject: reject_data,
        ticket_type_confirm: ticket_type_confirm_data,
        tenant_status: tenantStatus(),
        type: type,
        subtype: stype,
        tenant_name: tenant,
        technician: technician,
        appartment: appartment,
        recuring: recuring,
        description: description,
        rating: rating,
        review: review,
        date: date,
      });
    });

    setExportData(data);
  };

  function onClickFilter() {}

  function addTicket() {
    setVisible(true);
    setTrigger(0);
  }

  const getMedia = async (totalImages) => {
    const result = await getPresignedImage(totalImages); //change
    if (result && result.length > 0) {
    } else {
      message.error("Something wrong with Media");
    }
    return result;
  };

  const callImages = async (item, recuring, type = "") => {
    if (
      recuring !== "activity" &&
      recuring !== "rejectTicket" &&
      recuring !== "tenantDetail"
    ) {
      let totalImages = [];
      let totalVideos = [];
      if (item.ImageObject && item.ImageObject.length > 0) {
        item.ImageObject.map((x) => {
          totalImages.push(x.property_image);
        });
        const imagesA = await getMedia(totalImages);
        item.totalImageURL = imagesA;
      }
      if (item.VideoObject && item.VideoObject.length > 0) {
        item.VideoObject.map((x) => {
          totalVideos.push(x.property_video);
        });
        const videoA = await getMedia(totalVideos);
        item.totalVideoUrls = videoA;
      }
    }

    if (
      recuring &&
      recuring !== "activity" &&
      recuring !== "rejectTicket" &&
      recuring !== "tenantDetail"
    ) {
      setRecurringTicket({ ...item, ...{ ticketStatus: type } });
      setTrigger(3);
    } else if (recuring == "activity") {
      setActivityLogTicketData({ ...item, ...{ ticketStatus: type } });
      setTrigger(4);
    } else if (recuring == "rejectTicket") {
      setRejectTicketData({ ...item, ...{ ticketStatus: type } });
      setTrigger(5);
    } else if (recuring == "tenantDetail") {
      setTenantDetailData({ ...item, ...{ ticketStatus: type } });
      setTrigger(6);
    } else {
      setVisible(true);
      setSelectedTickets({ ...item, ...{ ticketStatus: type } });
      setTrigger(1);
    }
  };

  async function viewTicket(item, type = "") {
    callImages(item, false, type);
  }

  return (
    <>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Tickets"
              total={
                (ticketsApiNew?.dataCount || 0) +
                (ticketsApiProgress?.dataCount || 0) +
                (ticketsApiClosed?.dataCount || 0)
              }
              // showOption={technicians}
              // handleShow={handleShowTechnician}
              handleView={handleShowCategory}
              search={(e) => onTopSearch(e)}
              searchPlaceholder="Enter Ticket Number"
              // exportAction={onClickExport}
              // exportDataHeader={exportDataHeader}
              // exportData={exportData}
              filterAction={onClickFilter}
              addNewAction={addTicket}
              viewOption={ticketsType}
              viewOptionLabel="Category"
            />
            <Row gutter={24}>
              <Col xl={21} lg={24}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Tickets
                      ticketlist={ticketNew && ticketNew}
                      category="New"
                      count={ticketsApiNew?.dataCount || 0}
                      onCount={() => countUpdate("new")}
                      viewTicket={(e) => viewTicket(e, "new")}
                      showStatusOption={newStatusType}
                      handleStatusChange={handleNewStatusChange}
                      notifyCaseId={notifyCaseId}
                    />
                  </Col>
                  <Col span={8}>
                    <Tickets
                      ticketlist={ticketInProgress && ticketInProgress}
                      count={ticketsApiProgress?.dataCount || 0}
                      onCount={() => countUpdate("progress")}
                      category="In Progress"
                      viewTicket={(e) => viewTicket(e, "progress")}
                      showStatusOption={progressStatusType}
                      handleStatusChange={handleProgressStatusChange}
                      notifyCaseId={notifyCaseId}
                    />
                  </Col>
                  <Col span={8}>
                    <Tickets
                      ticketlist={ticketClosed && ticketClosed}
                      count={ticketsApiClosed?.dataCount || 0}
                      onCount={() => countUpdate("closed")}
                      category="Closed"
                      viewTicket={(e) => viewTicket(e, "closed")}
                      showStatusOption={completedStatusType}
                      handleStatusChange={handleCompletedStatusChange}
                      notifyCaseId={notifyCaseId}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};
