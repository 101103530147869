import React, { useState } from "react";
import { Layout, Row, Col, Typography, Image, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import storeLogo from "../../../assets/img/eqari-logo.svg";
import loginImage from "../../../assets/img/login-background.jpg";

const { Title, Text, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;

const bg = {
  backgroundImage: `url(${loginImage})`,
};

const LoginTemplate = (props) => {
  return (
    <Spin indicator={antIcon} size="large" spinning={props.load}>
      <Layout className="login-layout">
        <Row align="middle">
          <Col lg={10} sm={24} xs={24}>
            <div className="loginWrapper">
              <Row gutter={[0, 40]}>
                <Col span={24}>
                  <Space
                    direction="vertical"
                    size={16}
                    className="w-100"
                    align="center"
                  >
                    <Image
                      preview={false}
                      width={200}
                      src={storeLogo}
                      alt="EQARCOM logo"
                    />
                    <Text className="login-logo-slogan">
                      Your one stop solution to selling property.
                    </Text>
                  </Space>
                </Col>
                <Col span={24}>{props.children}</Col>
              </Row>
            </div>
          </Col>
          <Col lg={14} sm={24} xs={24}>
            <div className="login-banner" style={bg}>
              <div className="login-banner-caption">
                <Title level={2}>List your properties.</Title>
                <Paragraph className="RegularFont">
                  Start listing your properties with ease <br />
                  and manage your contracts.
                </Paragraph>
              </div>
              <div className="overlay"></div>
            </div>
          </Col>
        </Row>
      </Layout>
    </Spin>
  );
};

export default LoginTemplate;
