import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { maintenanceService } from "../../../../../configs/constants";
const zonelistUrl = `${maintenanceService}/facilityManager/fetchZones`;
const getTechniciansUrl = `${maintenanceService}/maintenance/technician/assign-building`;
const url = `${maintenanceService}/facilityManager/fetchTechnicianProperties`;

export const getAllZones = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(zonelistUrl);
      dispatch({
        type: action_types.GET_ZONES_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getAllTechnicians = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(getTechniciansUrl);
      dispatch({
        type: action_types.GET_TECH_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTechProperties = (id) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${url}?technicianId=${id}`);
      dispatch({
        type: action_types.GET_TECH_PROPERTY,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
