import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Upload,
  Space,
  Typography,
  message,
  Spin,
} from "antd";
import { useForm, useWatch, Controller } from "react-hook-form";
import {
  InputField,
  SelectField,
  DateField,
} from "../../../../../atoms/FormElement";
import { PlusCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getTicketType,
  getTicketSubType,
  getPropertyName,
  getTechnicians,
  resetTechnician,
  getPropertyUnits,
} from "../../ducks/actions";
import { maintenanceService } from "../../../../../../configs/constants";
import axios from "../../../../../../utils/axiosInceptor";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  uniquiFileName,
  getSingleUpload,
} from "../../../../../../utils/fileUpload";

var _ = require("lodash");
const { Text } = Typography;

const defaultVal = {
  tickettype: "",
  ticketsubtype: "",
  date: "",
  technician: "",
  desc: "",
  media: "",
  propertytype: "",
  tenant: "",
  unit_code: "",
};

const { Paragraph } = Typography;

const AddTicket = (props) => {
  const dispatch = useDispatch();

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [runVisible, setRunVisible] = useState(false);
  const ticketTypeApi = useSelector((state) => state.tickets.ticketsType);
  const ticketSubTypeApi = useSelector((state) => state.tickets.ticketsSubType);
  const propertyNameApi = useSelector((state) => state.tickets.propertyname);
  const technicianApi = useSelector(
    (state) => state.tickets.eligibletechnicians,
  );

  const fetchPropertyUnitsApi = useSelector(
    (state) => state.tickets.fetchPropertyUnits,
  );

  const [ticketsType, setTicketType] = useState([]);
  const [ticketsSubType, setTicketSubType] = useState([]);
  const [uploads, setUploads] = useState([]);
  // const [technician, setTechnician] = useState({});
  const [imageStatus, setImageStatus] = useState(Boolean);

  const { control, handleSubmit, errors, reset, setValue } = useForm({
    defaultValues: defaultVal,
  });

  let loggedInPartyId = JSON.parse(
    window.localStorage.getItem("login-response"),
  )?.data.userId;

  const handleChange = (e) => {
    setUploads(e.fileList);
  };

  const dummyRequest = (response) => {
    setTimeout(() => {
      response.onSuccess("ok");
    }, 0);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onBack = () => {
    reset();
    setRunVisible(false);
    dispatch(resetTechnician());
    props.onCancel();
  };

  useEffect(() => {
    ticketTypeApi.length === 0 && dispatch(getTicketType());
    propertyNameApi.length === 0 && dispatch(getPropertyName());
  }, []);

  useEffect(() => {
    ticketTypeApi && setTicketType(ticketTypeApi);
  }, [ticketTypeApi]);

  const checktype: any = useWatch({ control, name: "tickettype" });
  const datecheck: any = useWatch({ control, name: "date", defaultValue: "" });
  const propertyCheck: any = useWatch({ control, name: "propertytype" });
  const unit_code: any = useWatch({ control, name: "unit_code" });

  useEffect(() => {
    ticketSubTypeApi && setTicketSubType(ticketSubTypeApi);
  }, [ticketSubTypeApi]);

  useEffect(() => {
    if (checktype) {
      setValue("ticketsubtype", "");
      dispatch(getTicketSubType(checktype?.value));
    }
  }, [checktype]);

  useEffect(() => {
    if (checktype && moment(datecheck).isValid() && propertyCheck) {
      setValue("technician", "");
      let body = {
        case_category_id: checktype.value,
        preferred_date: datecheck,
        asset_id: propertyCheck.value,
      };
      dispatch(getTechnicians(body));
      dispatch(getPropertyUnits(propertyCheck.value));
    }
  }, [checktype, datecheck, propertyCheck, unit_code]);

  useEffect(() => {
    if (runVisible) {
      technicianApi.length < 1 && message.error("No Technician Available");
    } else {
      setRunVisible(true);
    }
  }, [technicianApi]);

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);

    const normalizedUploads = [];

    uploads.forEach((e) =>
      normalizedUploads.push({
        ...e,
        modifiedName: uniquiFileName(e?.originFileObj.name),
      }),
    );

    let noupload = false;

    await Promise.all(
      normalizedUploads.map(async (e, index) => {
        let res = null;
        res = await getSingleUpload(e.modifiedName, e.originFileObj);
        if (res == false) {
          noupload = true;
        }
      }),
    );

    if (noupload) {
      setLoader(false);
      setisError(true);
      setErrorMessage("Media not Uploaded. Please Try again");
      return false;
    } else {
      const mediaAsset = [];
      normalizedUploads.forEach((e) => {
        const medObj = {
          objectKey: e.modifiedName,
          mimeType: e.originFileObj.name.split(".")[1],
          contentType: e.type.split("/")[0],
        };
        mediaAsset.push(medObj);
      });
      const postJson = {
        assetId: values.propertytype.value,
        additionalDetails: values.desc,
        preferredDate: values.date,

        recurrenceId: 1,
        preferredTimeStart: "",
        preferredTimeEnd: "",
        alternateCountryCode: "",
        alternateContactNumber: "",

        categoryId: values.tickettype.value,
        subCategoryId: values.ticketsubtype.value,
        loggedInPartyId,
        multimedia_assets: mediaAsset,
        technicianPartyId: values.technician.value,
        unitId: values.unit_code.value,
      };
      const url = `${maintenanceService}/facilityManager/createTicket`;
      try {
        await axios.post(url, postJson);
        setTimeout(() => {
          reset();
          setLoader(false);
          message.success("Ticket added successfully");
          setSubmited(false);
          props.onSubmit();
        }, 3000);
      } catch (e) {
        setLoader(false);
        setisError(true);
        const { response } = e;
        setErrorMessage(response?.data?.message);
        console.log("check", response);
      }
    }
  };

  function disabledDate(current) {
    return current && current.valueOf() < Date.now();
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Button
        className="p-0 h-auto w-auto ag-default mb-1 modal_button"
        type="link"
        size="large"
        onClick={onBack}
        icon={<CloseOutlined />}
      />
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="tickettype"
                label="Ticket Type"
                control={control}
                rules={{ required: "Please select ticket type" }}
                selectOption={
                  ticketsType &&
                  ticketsType.map((item) => ({
                    value: item.ChildCaseCategory.id,
                    label: item.ChildCaseCategory.name,
                  }))
                }
                iProps={{ placeholder: "Select type" }}
                validate={errors.tickettype && "error"}
                validMessage={errors.tickettype && errors.tickettype.message}
                initValue=""
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="ticketsubtype"
                label="Ticket Sub-Type"
                control={control}
                iProps={{
                  placeholder: "Select Sub-Type",
                  isDisabled: !ticketsSubType.length,
                }}
                rules={{
                  required: "Please select ticket sub-type",
                }}
                initValue=""
                selectOption={
                  ticketsSubType &&
                  ticketsSubType.map((item) => ({
                    value: item.ChildCaseCategory.id,
                    label: item.ChildCaseCategory.name,
                  }))
                }
                validate={errors.ticketsubtype && "error"}
                validMessage={
                  errors.ticketsubtype && errors.ticketsubtype.message
                }
              />
            </Col>
            <Col span={12}>
              <InputField
                fieldname="desc"
                label="Description"
                control={control}
                iProps={{ placeholder: "Enter Description" }}
                initValue=""
              />
            </Col>
            <Col span={12}>
              <DateField
                isRequired={true}
                fieldname="date"
                label="Date"
                control={control}
                iProps={{
                  placeholder: "DD-MM-YYYY",
                  format: "DD-MM-YYYY",
                  disabledDate: disabledDate,
                }}
                rules={{
                  required: "Please enter date",
                  setValueAs: (value) => moment(value).format("YYYY-MM-DD"),
                }}
                initValue={null}
                validate={errors.date && "error"}
                validMessage={errors.date && errors.date.message}
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="propertytype"
                label="Apartment/Villa"
                control={control}
                iProps={{ placeholder: "Select Apartment/Villa" }}
                rules={{
                  required: "Please select Apartment/Villa",
                }}
                selectOption={
                  propertyNameApi &&
                  propertyNameApi.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                }
                validate={errors.propertytype && "error"}
                validMessage={
                  errors.propertytype && errors.propertytype.message
                }
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="unit_code"
                label="Unit"
                control={control}
                iProps={{ placeholder: "Select unit code" }}
                rules={{
                  required: "Please select unit code",
                }}
                selectOption={_.map(fetchPropertyUnitsApi, (item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                validate={errors.unit_code && "error"}
                validMessage={errors.unit_code && errors.unit_code.message}
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="technician"
                label="Technician"
                control={control}
                iProps={{ placeholder: "Select technician" }}
                rules={{
                  required: "Please select technician",
                }}
                selectOption={_.map(technicianApi, (item) => ({
                  value: item.Party.id,
                  label: item.Party.name,
                }))}
                validate={errors.technician && "error"}
                validMessage={errors.technician && errors.technician.message}
              />
            </Col>
            <Col span={24}>
              <Form.Item
                className="mb-0"
                validateStatus={errors.media && "error"}
                help={errors.media && errors.media.message}
              >
                <Controller
                  name="media"
                  control={control}
                  rules={{ required: "Please select attachment" }}
                  render={({ onChange, value }) => {
                    return (
                      <Upload
                        listType="picture-card"
                        fileList={value?.fileList}
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        multiple={true}
                        accept="image/*, video/*"
                        customRequest={dummyRequest}
                        onPreview={onPreview}
                        className="custom-upload mb-2"
                      >
                        <Space className="ag-success fontSize24" align="center">
                          <PlusCircleOutlined />
                          <Text className="ag-success RegularFont">Upload</Text>
                        </Space>
                      </Upload>
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" onClick={onBack}>
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddTicket;
