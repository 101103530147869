export const LOGOUT_INIT = "LOGOUT_INIT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NEW_NOTIFICATIONS = 'GET_NEW_NOTIFICATIONS';
export const NOTIFICATION_TYPE = {
    ASSET_ID: "asset_id",
    LEAD_ITEM_ID: "lead_item_id",
    MAINTENANCE_ITEM_ID: "case_id",
    LEASE_ID: "lead_id",
  };