import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  message,
  Spin,
  Space,
  Typography
} from "antd";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { DateField, InputField, SelectField } from "../../../../../atoms/FormElement";
import { getAllZones } from '../../ducks/actions';
import { getTicketType } from '../../../Tickets/ducks/actions';
import { maintenanceService } from "../../../../../../configs/constants";
import axios from '../../../../../../utils/axiosInceptor'

const defaultValues: any = {
  name: '',
  email_address: '',
  joining_date: '',
  zone_id: null,
};

const { Paragraph } = Typography;

const AddTechnician = props => {

  const dispatch = useDispatch();
  const { control, errors, reset, handleSubmit } = useForm({ defaultValues });
  const zoneApi = useSelector(state => state.technicians.zonesData);
  const typeApi = useSelector(state => state.tickets.ticketsType);
  const [zones, setZones] = useState([]);
  const [types, setTypes] = useState([]);
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(getAllZones());
    dispatch(getTicketType());
  }, [])

  useEffect(() => {
    zoneApi && setZones(zoneApi.map(item => ({ value: item.id, label: item.name })));
  }, [zoneApi])

  useEffect(() => {
    typeApi && setTypes(typeApi.map(item => ({ value: item.ChildCaseCategory.id, label: item.ChildCaseCategory.name })));
  }, [typeApi]);

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    const { name, email_address, joining_date, zone_id, speciality_ids, phone_number } = values;
    const postJson = {
      name,
      email_address,
      joining_date,
      zone_id: zone_id.value,
      phone_number,
      speciality_ids: speciality_ids.map(item => item.value)
    }
    const url = `${maintenanceService}/maintenance/createTechnician`;
    try {
      await axios.post(url, postJson, { headers: { application: 4 } });
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        reset();
        message.success('Technician added successfully');
        props.onCancel();
        props.onAddTech();
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message)
      console.log("check", response);
    }
  }


  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onFinish)}
    >
      {loader && !isError &&
        (<Row gutter={[24, 24]} >
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>)}
      {!submited ?
        (<>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label="Name"
                control={control}
                rules={{
                  required: "Please enter name",
                }}
                iProps={{ placeholder: "Ahmed Moustapha" }}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>
            <Col span={12}>
              <InputField
                isRequired={false}
                fieldname="email_address"
                label="Email Addresss"
                control={control}
                rules={{
                  pattern: {
                    value: /(?=^.{1,50}$)^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Enter valid Email Address",
                  },
                  required: "Please enter Email Address",
                }}
                iProps={{ placeholder: "ahmed.moustapha@gmail.com" }}
                initValue=""
                validate={errors.email_address && "error"}
                validMessage={errors.email_address && errors.email_address.message}
              />
            </Col>
            <Col span={12}>
              <DateField
                isRequired={true}
                fieldname="joining_date"
                label="Date of Joining"
                control={control}
                iProps={{ placeholder: "DD-MM-YYYY", format: "DD-MM-YYYY" }}
                rules={{
                  required: "Please enter joining date",
                  setValueAs: (value) => moment(value).format('YYYY-MM-DD'),
                }}
                initValue=""
                validate={errors.joining_date && "error"}
                validMessage={errors.joining_date && errors.joining_date.message}
              />
            </Col>
            <Col span={12}>
              <InputField
                isRequired={true}
                label="Phone"
                fieldname="phone_number"
                control={control}
                validate={errors.phone_number && "error"}
                validMessage={errors.phone_number && errors.phone_number.message}
                initValue=""
                rules={{
                  minLength: { value: 9, message: "Please enter valid mobile number, 9 digits required" },
                  maxLength: { value: 9, message: "Please enter valid mobile number, 9 digits required" },
                  required: "Please enter Mobile No.",
                  setValueAs: (value) => `+971${value}`,
                }}
                iProps={{ placeholder: 'Add Mobile Number', addonBefore: '+971', type: 'number' }}
              />
            </Col>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="speciality_ids"
                label="Speciality"
                control={control}
                iProps={{ placeholder: 'Select speciality', isMulti: true }}
                rules={{ required: "Please select speciality" }}
                initValue=''
                selectOption={types}
                validate={errors.speciality_ids && "error"}
                validMessage={errors.speciality_ids && errors.speciality_ids.message}
              />
            </Col>

            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="zone_id"
                label="Zone"
                control={control}
                iProps={{ placeholder: 'Select Zone' }}
                rules={{ required: "Please select zone" }}
                initValue=''
                selectOption={zones}
                validate={errors.zone_id && "error"}
                validMessage={errors.zone_id && errors.zone_id.message}
              />
            </Col>

          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" onClick={props.onCancel}>Back</Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">Add</Button>
            </Col>
          </Row>
        </>)
        :
        isError &&
        (<>
          <Row gutter={[24, 24]} >
            <Col span={24}>
              <Space size={24} className="w-100 text-center" direction="vertical">
                <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                <Paragraph className="ag-error fontWeight600 mb-0">{errorMessage}</Paragraph>
              </Space>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}><Button className="ag-gray-button-outline" htmlType="button" onClick={() => { setSubmited(false); setisError(false) }}>Back</Button></Col>
          </Row>
        </>)
      }
    </Form>
  );
};

export default AddTechnician;
