import React from "react";
import { Breadcrumb } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const BreadCrumb = (props) => {
  return (
    <Breadcrumb separator={<RightOutlined />}>
      {/* <Breadcrumb.Item>
        <Link to="/dashboard">Dashboard</Link>
      </Breadcrumb.Item> */}
      {props.list.map((item, index) => (
        <Breadcrumb.Item key={index}>
          <Link to={item.route}>{item.label}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadCrumb;
