import firebase from "firebase";
import * as firebaseEnums from "./constants";
const config = {
  apiKey: firebaseEnums.FIREBASE_API_KEY,
  authDomain: firebaseEnums.FIREBASE_AUTH_DOMAIN,
  projectId: firebaseEnums.FIREBASE_PROJECTID,
  storageBucket: firebaseEnums.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: firebaseEnums.FIREBASE_MESSAGING_SENDER_ID,
  appId: firebaseEnums.FIREBASE_APPID,
  measurementId: firebaseEnums.FIREBASE_MEASUREMENTID,
  databaseURL: "https://ekari-dev-default-rtdb.firebaseio.com",
};
const fire = firebase.initializeApp(config);

export default fire;
