import {
  Button,
  Col,
  List,
  Pagination,
  Row,
  Select,
  Space,
  Form,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import { leaseService } from "../../../configs/constants";
import axios from "../../../utils/axiosInceptor";
import DashboardLayout from "../../molecules/DashboardLayout";
import { useHistory, useLocation } from "react-router-dom";
import { NOTIFICATION_TYPE } from "../../molecules/Header/duck/constants";
import Link from "antd/lib/typography/Link";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications, getNewNotifications } from "./ducks/action";
import moment from "moment";

const { Title, Text } = Typography;
const { Option } = Select;

const Notification = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const url = `${leaseService}/leads/notifications/update/status`;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [optionSelected, setOptionSelected] = useState(
    location.state?.selected || "new",
  );
  const notificationsData = useSelector(
    (state: any) => state.notification.allNotify,
  );

  const newNotificationsData = useSelector(
    (state: any) => state.notification.allNewNotify,
  );

  useEffect(() => {
    dispatch(getNotifications(page));
    dispatch(getNewNotifications(page));
    console.log(location.state?.selected);
  }, []);

  useEffect(() => {
    notificationsData && setData(notificationsData);
  }, [notificationsData]);

  useEffect(() => {
    notificationsData && setData(newNotificationsData);
  }, [newNotificationsData]);

  const updateStatus = async (id) => {
    let postData = {
      notification_ids: [id],
    };
    await axios.post(url, postData);
    if (optionSelected === "new") {
      dispatch(getNewNotifications(page));
    } else {
      dispatch(getNotifications(page));
    }
  };

  const handleLinkClick = async (item) => {
    window.localStorage.setItem(
      "selectedOffer",
      JSON.stringify({
        identifier: item.identifier_value,
        id: item.id,
      }),
    );

    let postData = {
      notification_ids: [item.id],
    };
    await axios.post(url, postData);

    switch (item.type) {
      case NOTIFICATION_TYPE.ASSET_ID: {
        history.push(`/visit-scheduling/${item.identifier_value}`);
        break;
      }
      case NOTIFICATION_TYPE.LEAD_ITEM_ID: {
        history.push(`/offer-by-tenant/${item.identifier_value}`);
        break;
      }
      case NOTIFICATION_TYPE.MAINTENANCE_ITEM_ID: {
        history.push(`/maintenance/${item.identifier_value}`);
        break;
      }
      case NOTIFICATION_TYPE.LEASE_ID: {
        history.push(`/lease/${item.identifier_value}`);
        break;
      }
      default:
        history.push(`/booking-form/${item.identifier_value}`);
    }
  };

  useEffect(() => {
    optionSelected === "new"
      ? setData(newNotificationsData)
      : setData(notificationsData);
  }, [optionSelected]);

  const onSelectChange = (e) => {
    setPage(0);
    setOptionSelected(e);
  };

  const pageChange = (pg) => {
    setPage(pg);
    if (optionSelected === "new") {
      dispatch(getNewNotifications(pg));
    } else {
      dispatch(getNotifications(pg));
    }
  };

  return (
    <DashboardLayout>
      <Row gutter={24}>
        <Col span={24}>
          <Title level={3}>Notifications</Title>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Select
              style={{ width: 250 }}
              value={optionSelected}
              onChange={onSelectChange}
            >
              <Option value="new">New</Option>
              <Option value="all">All</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <List>
            {data.map((item, index) => (
              <List.Item
                key={index}
                className={`notificationlist ${
                  item.status === "seen" ? "notify-read" : ""
                }`}
                actions={[
                  item.status === "created" && (
                    <Button
                      className="ag-success"
                      type="link"
                      onClick={() => updateStatus(item.id)}
                    >
                      Mark as Read
                    </Button>
                  ),
                ]}
              >
                <Space direction="vertical" size={4}>
                  <Text className="ag-primary breakText">
                    {item.text || ""}
                  </Text>
                  <Text className="smallFont12">
                    <Link
                      className="ag-secondary"
                      onClick={() => handleLinkClick(item)}
                    >
                      {item.identifier || "Click here."}
                    </Link>
                  </Text>
                  <Text className="smallFont12">
                    {item.createdAt
                      ? moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")
                      : ""}
                  </Text>
                </Space>
              </List.Item>
            ))}
          </List>
        </Col>
        <Col span={24}>
          <Pagination
            className="ag-property-text-pagination"
            total={data[0]?.total_count}
            showTotal={(total, range) =>
              `Showing ${range[0]}-${range[1]} Notification`
            }
            defaultPageSize={10}
            showSizeChanger={false}
            current={page + 1}
            onChange={(e) => pageChange(e - 1)}
          />
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default Notification;
