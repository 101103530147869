import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Space,
  Tag,
  Button,
  Image,
  Divider,
  Avatar,
  List,
} from "antd";
import { EyeFilled, CloseOutlined, CaretRightFilled } from "@ant-design/icons";
import ThumbImg from "../../../../../../assets/img/ticket_thumb.jpg";
import { maintenanceService } from "../../../../../../configs/constants";
import axios from "../../../../../../utils/axiosInceptor";
import Icon from "@ant-design/icons";

const { Title, Text, Paragraph } = Typography;

const BackSvg = () => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.41994 13.0598C8.25755 13.0598 8.09346 13.0057 7.95644 12.8974L0.787449 7.13078C0.613215 6.99038 0.511719 6.77893 0.511719 6.55395C0.511719 6.33066 0.613215 6.11752 0.787449 5.97881L7.92599 0.242597C8.24401 -0.012834 8.7092 0.037914 8.96463 0.355934C9.22006 0.673955 9.16932 1.13914 8.8513 1.39458L2.42999 6.55395L8.88344 11.7455C9.20146 12.0009 9.2522 12.4661 8.99677 12.7841C8.8496 12.9651 8.63646 13.0598 8.41994 13.0598Z"
      fill="#184461"
    />
  </svg>
);
const BackIcon = (props) => <Icon component={BackSvg} {...props} />;

const ViewTenantDetail = (props) => {
  const { data, onCancel, onBack } = props;
  const [tenantDetail, setTenantDetail] = useState({
    full_name: null,
    email: null,
    phone_no: null,
    building: null,
    alternate_number: null,
    unit_code: null,
  });

  let tickid = data?.Asset?.AssetIdentifier?.identifier;

  let category_id = data.CaseCategoryClassification?.[0]?.case_category_id;
  let sub_category_id =
    data.CaseCategorySecondaryClassification?.[0]?.case_category_id;

  useEffect(() => {
    let party_id = data?.tenant_party_id;
    let case_id = data?.id;

    const url = `${maintenanceService}/facilitymanager/case/${case_id}/tenant/${party_id}`;

    axios.get(url).then(
      (response) => {
        let result = response?.data?.result;
        let al_number =
          result?.alternate_contact_info?.telecommunication_number?.[0];
        setTenantDetail({
          unit_code: result?.code,
          full_name: result?.first_name + " " + result?.last_name,
          email: result?.contact_info?.electronic_address?.[0]?.email,
          phone_no:
            result?.contact_info?.telecommunication_number?.[0]?.contact_number,
          alternate_number: al_number?.contact_number
            ? al_number?.country_code
              ? al_number?.country_code + " " + al_number?.contact_number
              : al_number?.contact_number
            : "N/A",
          building: result?.assetName,
        });
      },
      (error) => {
        console.log(error);
      },
    );
  }, [data]);

  return (
    <Row gutter={24}>
      <Col span={24}>
        <Button
          className="p-0 h-auto w-auto ag-default mb-1"
          type="link"
          size="large"
          onClick={onCancel}
          icon={<CloseOutlined />}
        />
      </Col>
      <Col span={24}>
        <Button
          className="p-0 h-auto w-auto ag-primary mb-1"
          type="link"
          size="large"
          onClick={onBack}
        >
          <BackIcon /> Back to ticket details
        </Button>
      </Col>
      <Col span={24}>
        <Space size={24} className="w-100" direction="vertical">
          <Title level={3}>Tenant Details</Title>
        </Space>
      </Col>
      <Col span={24}>
        <Row gutter={24} className="tenant-details-body">
          <Col span={6}>
            <Paragraph className="ag-primary mt-1">Unit Code</Paragraph>
            <Paragraph className="ag-black">
              {tenantDetail?.unit_code}
            </Paragraph>
          </Col>
          <Col span={6}>
            <Paragraph className="ag-primary mt-1">Building Name</Paragraph>
            <Paragraph className="ag-black">{tenantDetail?.building}</Paragraph>
          </Col>
          <Col span={6}>
            <Paragraph className="ag-primary mt-1">Full Name</Paragraph>
            <Paragraph className="ag-black">{tenantDetail.full_name}</Paragraph>
          </Col>
          <Col span={6}>
            <Paragraph className="ag-primary mt-1">
              Registered Phone Number
            </Paragraph>
            <Paragraph className="ag-black">{tenantDetail.phone_no}</Paragraph>
          </Col>
        </Row>
        <Row gutter={24} className="tenant-details-body">
          <Col span={6}>
            <Paragraph className="ag-primary mt-1">
              Registered Email Address
            </Paragraph>
            <Paragraph className="ag-black">{tenantDetail.email}</Paragraph>
          </Col>
          <Col span={6}>
            <Paragraph className="ag-primary mt-1">
              Alternate Phone Number
            </Paragraph>
            <Paragraph className="ag-black">
              {tenantDetail?.alternate_number}
            </Paragraph>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ViewTenantDetail;
