import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Input,
  DatePicker,
  Space,
  Button,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

import Grid from "../../../atoms/Grid/";
import { Popup } from "../../../atoms/Popup";
import SectionHeader from "../../../molecules/SectionHeader";
import TechnicianSchedule from "./components/TechnicianSchedule";
import { useDispatch, useSelector } from "react-redux";
import { getAllDutyRoster } from "../../../modules/Maintenance/Roster/components/TechnicianSchedule/ducks/actions";
import DashboardLayout from "../../../molecules/DashboardLayout";
import BreadCrumb from "../../../atoms/BreadCrumb";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import EditTechnicianSchedule from "./components/TechnicianSchedule/Edit";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const gridProps = {
  sortable: false,
  filter: false,
  suppressMovable: true,
};

// function extractValues(mappings) {
//   return Object.keys(mappings);
// }

// function lookupValue(mappings, key) {
//   return mappings[key];
// }

const initArray: any = [];

const breadcrumbList: any = [
  {
    label: "Maintenance",
    route: "/roster",
  },
  {
    label: "Roster",
    route: "/roster",
  },
];

export default () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [searchText, setSearchText] = useState(null);
  const [rosterDataNormalized, setRosterDataNormalized] = useState([]);
  const [rosterEditData, setRosterEditData] = useState({});
  const [onClose, setOnClose] = useState(false);

  const searchField = (
    <Input
      type="text"
      onChange={(e) => onSearch(e)}
      placeholder=""
      suffix={<SearchOutlined />}
    />
  );
  const [dates, setDates] = useState([]);
  const [value, setValue] = useState(initArray);
  const [hackValue, setHackValue] = useState(initArray);
  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 7;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 7;
    return tooEarly || tooLate;
  };

  var customDot = function (params) {
    let col = "";
    if (params.value === "day_off") {
      col = "orange-dot";
    } else if (params.value === "work") {
      col = "blue-dot";
    } else if (params.value === "vacation") {
      col = "green-dot";
    } else if (params.value === "sick") {
      col = "gray-dot";
    } else if (params.value === "public_holiday") {
      col = "lightblue-dot";
    } else if (params.value === "weekend") {
      col = "darkgreen-dot";
    }
    return col;
  };

  const handleEdit = (data) => {
    // console.log(data?.colDef?.field)
    // console.log(data?.data?.dayAndDate[data?.colDef?.field])
    // console.log(data?.data)
    setRosterEditData(data);
    setVisible(true);
    setTrigger(1);
  };

  const actionEditDelete = (params) => {
    if (params.data.dayTime[params?.colDef?.field] !== undefined) {
      const [startingTime, endindTime] = params.data.dayTime[
        params?.colDef?.field
      ];
      return (
        <>
          {customDot(params) && (
            <Space size={16}>
              <Button
                type="link"
                className="p-0 ag-success"
                onClick={() => handleEdit(params)}
              >
                <span className={`circle ${customDot(params)}`}></span>
                {startingTime && (
                  <Tag className="my-tag">
                    {startingTime + " | " + endindTime}
                  </Tag>
                )}
              </Button>
            </Space>
          )}
        </>
      );
    }

    return <></>;
  };

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  const onSubmit = () => {
    handleDateRange(value);
    setVisible(false);
  };

  const rosterCol = [
    {
      headerName: "ID",
      valueGetter: "node.rowIndex + 1",
      maxWidth: 70,
    },
    {
      headerName: "Technician Name",
      field: "technicianName",
    },
    {
      headerName: "Sunday",
      field: "sunday",
      //cellRenderer: customDot,
      headerClass: "ag-column-center",
      cellRendererFramework: actionEditDelete,
      cellClass: "text-center",
    },
    {
      headerName: "Monday",
      field: "monday",
      //cellRenderer: customDot,
      cellRendererFramework: actionEditDelete,
      headerClass: "ag-column-center",
      cellClass: "text-center",
    },
    {
      headerName: "Tuesday",
      field: "tuesday",
      //cellRenderer: customDot,
      cellRendererFramework: actionEditDelete,
      headerClass: "ag-column-center",
      cellClass: "text-center",
    },
    {
      headerName: "Wednesday",
      field: "wednesday",
      //cellRenderer: customDot,
      cellRendererFramework: actionEditDelete,
      headerClass: "ag-column-center",
      cellClass: "text-center",
    },
    {
      headerName: "Thursday",
      field: "thursday",
      //cellRenderer: customDot,
      cellRendererFramework: actionEditDelete,
      headerClass: "ag-column-center",
      cellClass: "text-center",
    },
    {
      headerName: "Firday",
      field: "friday",
      //cellRenderer: customDot,
      cellRendererFramework: actionEditDelete,
      headerClass: "ag-column-center",
      cellClass: "text-center",
    },
    {
      headerName: "Saturday",
      field: "saturday",
      //cellRenderer: customDot,
      cellRendererFramework: actionEditDelete,
      headerClass: "ag-column-center",
      cellClass: "text-center",
    },
    // {
    //   headerName: "Action",
    //   field: "action",
    //   cellRendererFramework: actionEditDelete,
    //   headerClass: "ag-column-center",
    //   cellClass: "text-center"
    // },
  ];

  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Add Technician’s Schedule
        </Title>
      ),
      content: (
        <TechnicianSchedule
          onCancel={() => setVisible(false)}
          onSubmit={onSubmit}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Edit Technician’s Schedule
        </Title>
      ),
      content: (
        <EditTechnicianSchedule
          onClose={onClose}
          setOnClose={setOnClose}
          setVisible={setVisible}
          onCancel={() => setVisible(false)}
          onSubmit={onSubmit}
          rosterEditData={rosterEditData}
        />
      ),
      width: 650,
    },
  ];
  const rosterDuties = useSelector(
    (state) => state.rosterReducer.getAllDutyRoster,
  );

  const toDate = () => {
    let startdate = moment(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000));
    let enddate = moment(new Date(Date.now()));
    let temp = [];
    temp.push(startdate, enddate);
    setValue(temp);
    dispatch(
      getAllDutyRoster(
        startdate.format("YYYY-MM-DD"),
        enddate.format("YYYY-MM-DD"),
      ),
    );
  };

  useEffect(() => {
    toDate();
  }, []);

  useEffect(() => {
    rosterDuties && console.log("roster data", rosterDuties);
  }, [rosterDuties]);

  useEffect(() => {
    if (rosterDuties.length) {
      const normalized = [];
      rosterDuties.map((e) => {
        const dayStatus = [];
        const dayAndDate = [];
        const dayTime = [];

        if (e.length > 0) {
          console.log("this is return", e);
          for (let i = 0; i < e[0]?.TimeEntry.length; i++) {
            dayStatus.push({
              day: e[0]?.TimeEntry[i].TimeSheetDate.week_day_name
                .toLowerCase()
                .trim(),
              status: e[0]?.TimeEntry[
                i
              ].TimeEntryStatus.TimeEntryStatusType.name.toLowerCase(),
              statusId:
                e[0]?.TimeEntry[i].TimeEntryStatus.TimeEntryStatusType.id,

              timesheet_date: e[0]?.TimeEntry[i].TimeSheetDate.date,
            });

            dayAndDate[
              e[0]?.TimeEntry[i].TimeSheetDate.week_day_name
                .toLowerCase()
                .trim()
            ] = e[0]?.TimeEntry[i].TimeSheetDate.date;

            dayTime[
              e[0]?.TimeEntry[i].TimeSheetDate.week_day_name
                .toLowerCase()
                .trim()
            ] = [
              e[0]?.TimeEntry[i].starting_time,
              e[0]?.TimeEntry[i].ending_time,
            ];
          }

          normalized.push({
            id: e[0].id,
            technicianName: e[0]?.TimeSheetRole?.Party?.name,
            technicianId: e[0]?.TimeSheetRole?.Party?.id,
            starting_time: e[0]?.TimeEntry?.[0]?.starting_time,
            ending_time: e[0]?.TimeEntry?.[0]?.ending_time,
            starting_date: value[0],
            ending_date: value[1],
            sunday: dayStatus.filter((e) => e.day === "sunday")[0]?.status,
            monday: dayStatus.filter((e) => e.day === "monday")[0]?.status,
            tuesday: dayStatus.filter((e) => e.day === "tuesday")[0]?.status,
            wednesday: dayStatus.filter((e) => e.day === "wednesday")[0]
              ?.status,
            thursday: dayStatus.filter((e) => e.day === "thursday")[0]?.status,
            friday: dayStatus.filter((e) => e.day === "friday")[0]?.status,
            saturday: dayStatus.filter((e) => e.day === "saturday")[0]?.status,
            location: "Deira",
            //data:e,
            dayTime: dayTime,
            dayStatus: dayStatus,
            dayAndDate: dayAndDate,
          });
        }
      });
      setRosterDataNormalized(normalized);
    }
  }, [rosterDuties]);

  const handleDateRange = (date) => {
    setValue(date);
    if (date && date.length) {
      dispatch(
        getAllDutyRoster(
          date[0].format("YYYY-MM-DD"),
          date[1].format("YYYY-MM-DD"),
        ),
      );
    }
  };

  const onCancel = () => {
    setOnClose(true);
    setVisible(false);
  };
  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => onCancel(),
  };

  function handleShow(value) {}

  function onTopSearch(value) {}

  const onClickFilter = () => {
    console.log("filter click");
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const addSchedule = () => {
    setVisible(true);
    setTrigger(0);
  };

  return (
    <>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Roster"
              total={rosterDataNormalized.length}
              filterAction={onClickFilter}
              addNewAction={addSchedule}
            />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={24}>
                  <Col flex="1 1 390px">
                    <Space size={24}>
                      <RangePicker
                        size="large"
                        value={value}
                        onChange={(e) => handleDateRange(e)}
                        disabledDate={disabledDate}
                        onCalendarChange={(val) => setDates(val)}
                        onOpenChange={onOpenChange}
                      />
                      <Button type="primary" size="large" onClick={toDate}>
                        Jump to Today
                      </Button>
                    </Space>
                  </Col>
                  <Col flex="0 1 auto">
                    <Space size={16} align="end" className="h-100">
                      <Space size={8} align="center">
                        <Text className="circle blue-dot"></Text>
                        <Text className="defaultFont">Work</Text>
                      </Space>
                      <Space size={8} align="center">
                        <Text className="circle green-dot"></Text>
                        <Text className="defaultFont">Vacation</Text>
                      </Space>
                      <Space size={8} align="center">
                        <Text className="circle darkgreen-dot"></Text>
                        <Text className="defaultFont">Weekend</Text>
                      </Space>
                      <Space size={8} align="center">
                        <Text className="circle lightblue-dot"></Text>
                        <Text className="defaultFont">Public Holiday</Text>
                      </Space>

                      <Space size={8} align="center">
                        <Text className="circle orange-dot"></Text>
                        <Text className="defaultFont">Day Off</Text>
                      </Space>
                      <Space size={8} align="center">
                        <Text className="circle gray-dot"></Text>
                        <Text className="defaultFont">Sick</Text>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Card
                  bordered={false}
                  className="ag-nospace-body roster-listtable"
                  extra={searchField}
                >
                  <Grid
                    data={rosterDataNormalized}
                    columns={rosterCol}
                    pagination={false}
                    defaultSettings={gridProps}
                    filterGrid={searchText}
                    noRowlabel="Schedules"
                    noRowbtnAction={addSchedule}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};
