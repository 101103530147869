import React from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
} from "antd";

const AddTicketTypes = props => {
  return (
    <Form layout="vertical">
      <Row gutter={24}>

        <Col span={12}>
          <Form.Item name="tickettype" label="Name of Ticket Type" rules={[{ required: true }]} initialValue="Plumbing">
            <Input placeholder="Enter Ticket Type" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="speciality" label="Speciality" rules={[{ required: true }]} initialValue="Plumber">
            <Input placeholder="Enter Ticket Type" />
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button className="ag-gray-button-outline" onClick={props.onCancel}>Back</Button>
        </Col>
        <Col>
          <Button className="ag-green-button" onClick={props.onAdd}>Add</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddTicketTypes;
