import React, { useEffect } from "react";
import { Layout, Row, Col } from "antd";
import Sidebar from "../../molecules/Sidebar";
import Header from "../../molecules/Header";
import Footer from "../../molecules/Footer";

import { useSelector, useDispatch } from "react-redux";
import { getDeviceToken } from "./Tickets/ducks/actions";
import Analytics from "./dashboardComponent/Analytics";
import TechnicianMap from "./dashboardComponent/TechnicianMap";
import TicketChart from "./dashboardComponent/TicketChart";
import TechnicianGrid from "./dashboardComponent/TechnicianGrid";
import FiguresHead from "./dashboardComponent/FiguresHead";

const { Content } = Layout;

export default () => {
  const dispatch = useDispatch();
  const deviceTokenValue = useSelector(
    (state) => state.maintenance.getDeviceToken,
  );

  console.log("check render");

  useEffect(() => {
    // let messageRef = fire
    //   .database()
    //   .ref("messages")
    //   .orderByKey()
    //   .limitToLast(100);
    // messageRef.on("child_added", (snapshot) => {
    //   let message = { text: snapshot.val(), id: snapshot.key };
    //   console.log("FIRE MESSAGE", message);
    // });
    dispatch(getDeviceToken());
  }, []);

  return (
    <Layout className="main-layout">
      <Header />
      <Layout>
        <Sidebar />
        <Layout className="layout-space">
          <FiguresHead />
          <Content className="main-content">
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <TechnicianMap />
              </Col>
              <Col span={12}>
                <Analytics />
              </Col>
              <Col span={12}>
                <TicketChart />
              </Col>
              <Col span={12}>
                <TechnicianGrid />
              </Col>
            </Row>
            <Footer />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
