import React, { useState, useEffect, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  Button,
  Select,
  Form,
  Skeleton,
} from "antd";

import HeadingChip from "../../atoms/HeadingChip";
import TicketChip from "../../atoms/TicketChip";

const { Title } = Typography;

const Tickets = (props) => {
  const [tickets, setTickets] = useState([]);
  const [loader, setLoader] = useState(true);
  const {
    ticketlist,
    category,
    viewTicket,
    count,
    onCount,
    handleStatusChange,
    showStatusOption,
  } = props;

  useEffect(() => {
    if (ticketlist != null) {
      setTickets(ticketlist);
      setLoader(false);
    } else {
      setTickets([]);
      setLoader(true);
    }
  }, [ticketlist]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <HeadingChip heading={category} value={count} />
      </Col>
      <Col span={24}>
        <Card bordered={false} style={{ borderRadius: 4 }}>
          {showStatusOption && showStatusOption.length && (
            <Select
              bordered={true}
              onChange={handleStatusChange}
              size="large"
              options={showStatusOption}
              style={{ width: "100%" }}
              defaultValue="All"
            />
          )}
          {tickets.length > 0 ? (
            <Space size={24} direction="vertical" className="w-100">
              <Card
                bordered={false}
                className="ag-nospace-body ag-noborder-head"
                style={{
                  height: 550,
                  overflowY: "auto",
                }}
              >
                <Space size={24} direction="vertical" className="w-100">
                  {tickets.map((item: any, index) => (
                    <TicketChip
                      key={index}
                      ticketID={item.case_id}
                      type={item?.case_category_primary_display_name}
                      subtype={item?.case_category_secondary_display_name}
                      status={item.ticket_last_status}
                      statusDisplayName={
                        showStatusOption.find(
                          (element) =>
                            element.value == item.case_status_type_name,
                        )?.label
                      }
                      tenant={
                        // item.Asset.AssetRole[0].PartyRoleType.name
                        item.case_role_tenant_party_name || ""
                      }
                      appartment={item?.building_name}
                      location={item.location}
                      recuring={item.ticket_parent_id}
                      clickView={() => viewTicket(item)}
                    />
                  ))}
                </Space>
              </Card>
              {tickets.length < count ? (
                <Button type="link" block onClick={onCount}>
                  View More
                </Button>
              ) : (
                <Button></Button>
              )}
            </Space>
          ) : (
            <Fragment>
              <Skeleton loading={loader} active paragraph />
              {!loader && (
                <Title
                  level={5}
                  className="highlight ag-primary mb-0 p-1 text-center"
                >
                  There are currently no {category === "New" && "new"}
                  {category === "Closed" && "closed"} tickets{" "}
                  {category === "In Progress" && "in progress"}
                </Title>
              )}
            </Fragment>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default Tickets;
