/* eslint-disable react/prop-types */
import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { checkCookie, deleteCookie } from "../utils/helper";

import Login from "../app/pages/Login";
import Dashboard from "../app/pages/Dashboard";
import SignUp from "../app/modules/SignUp";
import ForgotPassword from "../app/modules/Login/ForgotPassword";
import ChangePassword from "../app/modules/Login/ChangePassword";
import Success from "../app/modules/Login/Success";
import Tickets from "../app/modules/Maintenance/Tickets/Tickets";
import Zones from "../app/modules/Maintenance/Zones/Zones";
import Technicians from "../app/modules/Maintenance/Technicians/Technicians";
import Roster from "../app/modules/Maintenance/Roster/Roster";
import Maintenance from "../app/modules/Maintenance";
import LiveTracking from "../app/modules/Maintenance/LiveTracking/LiveTracking";
import TicketTypes from "../app/modules/Maintenance/TicketTypes/TicketTypes";
import SignUpNew from "../app/modules/SignUpNew";
import OTP from "../app/modules/SignUpNew/OTP";
import CreatePassword from "../app/modules/SignUpNew/CreatePassword";
import Notification from "../app/modules/Notification";

const Pages = (props) => {
  const {
    match: { path },
  } = props;

  function isAuthenticated() {
    const login = JSON.parse(localStorage.getItem("login-response"));
    try {
      const { exp } = login;
      if (Date.now() >= exp * 1000) {
        return false;
      }
    } catch (err) {
      return false;
    }
    return true;
  }

  // function clearAll() {
  //   deleteCookie("landlordUser");
  //   // localStorage.clear();
  //   sessionStorage.clear();
  // }

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={SignUp} />
      <Route exact path="/signup" component={SignUpNew} />
      <Route exact path="/otp" component={OTP} />
      <Route exact path="/create-password" component={CreatePassword} />
      <Route exact path="/success" component={Success} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/change-password" component={ChangePassword} />

      <Route path="/dashboard" component={Maintenance} />
      <Route path="/tickets" component={Tickets} />
      <Route path="/technicians" component={Technicians} />
      <Route path="/roster" component={Roster} />
      <Route path="/zones" component={Zones} />
      <Route path="/livetracking" component={LiveTracking} />
      <Route path="/ticket-types" component={TicketTypes} />
      <Route exact path="/notifications" component={Notification} />

      <Redirect to="/" />
    </Switch>
  );
};

export default Pages;
