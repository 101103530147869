import * as action_types from "./constants";

const initialState: any = {
  techData: [],
  zonesData: [],
  techProperty: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_ZONES_LIST:
      return { ...state, zonesData: data };
    case action_types.GET_TECH_LIST:
      return { ...state, techData: data };
    case action_types.GET_TECH_PROPERTY:
      return { ...state, techProperty: data };
    default:
      return state;
  }
};
