/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Typography, Form, Button, Space, message } from "antd";
import LoginTemplate from "./LoginTemplate";
import { UserOutlined } from "@ant-design/icons";
import { authService } from "../../../configs/constants";
import axios from "axios";
import { useForm } from "react-hook-form";
import { InputField } from "../../atoms/FormElement";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { SITEKEY } from "../../../configs/constants";

const { Text } = Typography;

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [invalidUserFlag, setInvalidUserFlag] = useState(false);
  const [onSuccessCodeSent, setOnSuccessCodeSent] = useState(false);
  const history = useHistory();
  const { control, handleSubmit, errors } = useForm();

  const [tokenCapcha, setReCapcha] = useState();
  const refReCaptcha = useRef(null);
  const verifyCallback = (token) => {
    setReCapcha(token);
  };
  useEffect(() => {
    loadReCaptcha(SITEKEY);
  }, []);

  const onFinish = async (values) => {
    setInvalidUserFlag(false);
    const { username } = values;
    const postJson = {
      username: username,
      recapcha: tokenCapcha,
    };
    const url = `${authService}/user/forgot-password`;
    try {
      await axios.post(url, postJson);
      setInvalidUserFlag(false);
      setOnSuccessCodeSent(true);
      window.localStorage.setItem("usernameForCode", username);
      // setTimeout(() => {
      //   history.push("/change-password");
      // }, 30 * 1000);
    } catch (e) {
      setOnSuccessCodeSent(false);
      const { response } = e;
      refReCaptcha.current.execute();
      if (response?.statusText.includes("check the email")) {
        setInvalidUserFlag(true);
      } else {
        message.error(response.data.message);
      }
    }
  };

  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Text className="ag-primary RegularFont">Forgot Password</Text>
        </Col>
        <Col span={24}>
          <Form
            name="normal_login"
            className="forgot-password-form text-left"
            onFinish={handleSubmit(onFinish)}
          >
            <InputField
              fieldname="username"
              control={control}
              initValue=""
              iProps={{
                placeholder: "Enter Email",
                size: "large",
                prefix: <UserOutlined className="site-form-item-icon" />,
              }}
              rules={{
                required: "Please input username",
                pattern: {
                  value: /^\S*$/,
                  message: "Input username without space!",
                },
              }}
              validate={errors.username && "error"}
              validMessage={errors.username && errors.username.message}
            />

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
              >
                Send code
              </Button>
            </Form.Item>
            {invalidUserFlag && (
              <div className="ant-form-item-explain defaultFont">
                {/* <Text className="ag-error">
                  There is no account associated with this email address.
                  Re-enter or{" "}
                  <Link to="/signup" className="text-underline">
                    create a new account.
                  </Link>
                </Text> */}
                <Space direction="vertical" size={16}>
                  <Text className="ag-success">
                    An email has been sent to this email address with a code you
                    will need to make a new password.
                  </Text>
                  {/* <Space direction="vertical" size={16}>
                    
                    <Button
                      type="link"
                      size="large"
                      htmlType="submit"
                      className="p-0"
                      danger
                    >
                      Did not receive code? Resend now.
                    </Button>
                  </Space> */}
                </Space>
              </div>
            )}
            {onSuccessCodeSent && (
              <div className="ant-form-item-explain defaultFont">
                <Space direction="vertical" size={16}>
                  <Text className="ag-success">
                    An email has been sent to this email address with a code you
                    will need to make a new password.
                  </Text>
                  <Space direction="vertical" size={16}>
                    <Link to="/change-password">I have my code.</Link>
                    <Button
                      type="link"
                      size="large"
                      htmlType="submit"
                      className="p-0"
                      danger
                    >
                      Did not receive code? Resend now.
                    </Button>
                  </Space>
                </Space>
              </div>
            )}
            <ReCaptcha
              action="main"
              ref={refReCaptcha}
              sitekey={SITEKEY}
              verifyCallback={verifyCallback}
            />
          </Form>
        </Col>
      </Row>
    </LoginTemplate>
  );
};

export default ForgotPassword;
