import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Tabs,
  Input,
  Typography,
  Button,
  message,
  Tag,
  Select,
  Pagination,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

import Grid from "../../../atoms/Grid/";
import { Popup } from "../../../atoms/Popup";
import SectionHeader from "../../../molecules/SectionHeader";

import { useSelector, useDispatch } from "react-redux";

import AddTechnician from "./components/AddTechnician";
import ApproveTechnician from "./components/ApproveTechnician";
import RejectTechnician from "./components/RejectTechnician";

import { getAllTechnicians, getTechProperties } from "./ducks/actions";
import DashboardLayout from "../../../molecules/DashboardLayout";
import BreadCrumb from "../../../atoms/BreadCrumb";
import { getTechnicains } from "../Roster/components/TechnicianSchedule/ducks/actions";
import AddRemoveSpecialzation from "./components/AddRemoveSpecialzation";

var _ = require("lodash");
const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
};

const breadcrumbList: any = [
  {
    label: "Maintenance",
    route: "/technicians",
  },
  {
    label: "Technicians",
    route: "/technicians",
  },
];

const limit = 20;

export default () => {
  const techlistApi = useSelector((state) => state.technicians.techData);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [tid, setTID] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const [tabKey, setTabkey] = useState("1");
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(1);
  const [techData, setTechData] = useState({});
  const [special, setSpecial] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [techniciansAssigned, setTechniciansAssigned] = useState([]);
  const techniciansData = useSelector(
    (state) => state.rosterReducer.getTechnicians,
  );

  const searchField = (
    <Input
      type="text"
      onChange={(e) => onSearch(e)}
      placeholder=""
      suffix={<SearchOutlined />}
    />
  );

  const technicianCol = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      maxWidth: 100,
    },
    {
      headerName: "Technician Name",
      field: "first_name",
    },
    {
      headerName: "Email Address",
      field: "email",
    },
    {
      headerName: "Mobile Number",
      field: "number",
    },
    {
      headerName: "Area of Specialization",
      field: "speciality",
      cellRendererFramework: specialityRender,
    },
    {
      headerName: "Assigned Building",
      field: "property_name",
    },
    {
      headerName: "Status",
      field: "status",
      cellRendererFramework: statusRender,
    },
  ];

  const assignTechCol = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      maxWidth: 100,
    },
    {
      headerName: "Technician Name",
      field: "first_name",
    },
    {
      headerName: "Email Address",
      field: "email",
    },
    {
      headerName: "Mobile Number",
      field: "number",
    },
    {
      headerName: "Area of Specialization",
      field: "speciality",
      cellRendererFramework: specialityRender2,
    },
    {
      headerName: "Assigned Building",
      field: "property_name",
      cellRendererFramework: propertyRender,
    },
  ];

  function statusRender(params) {
    if (params.value === "pending_approval") {
      return (
        <Button
          type="link"
          className="p-0 fontWeight600"
          onClick={() => approveTechnician(params)}
        >
          Pending Approval
        </Button>
      );
    } else if (params.value === "revoked") {
      return <span className="ag-error fontWeight600">Rejected</span>;
    } else if (params.value === "approved") {
      return <span className="ag-success fontWeight600">Approved</span>;
    } else {
      return (
        <span className="ag-success fontWeight600 Sentence">
          {params.value}
        </span>
      );
    }
  }

  function specialityRender2(params) {
    return (
      <Button
        type="link"
        onClick={() => updateSpecialization(params.value, params.data.techid)}
        className="p-0 ag-success"
      >
        {params.value &&
          params.value.map((item, index) => (
            <Tag className="" key={index}>
              {item.CaseCategory.display_name}
            </Tag>
          ))}
      </Button>
    );
  }

  function specialityRender(params) {
    return (
      <>
        {params.value &&
          params.value.map((item, index) => (
            <Tag className="" key={index}>
              {item.display_name}
            </Tag>
          ))}
      </>
    );
  }

  function propertyRender(params) {
    const assignBuild = useSelector((state) => state.technicians.techProperty);

    return (
      <Select
        className="Sentence"
        style={{ width: 150 }}
        placeholder="See Buildings"
        value={null}
        onDropdownVisibleChange={() =>
          dispatch(getTechProperties(params.data.id))
        }
      >
        {_.map(assignBuild, (d, index) => (
          <Option key={index} value={d.id}>
            {d.name}
          </Option>
        ))}
      </Select>
    );
  }

  const onSearch = (e) => {
    if (e.target.value) {
      setPage(1);
      setSearchText(e.target.value);
      dispatch(getTechnicains(1, limit, searchText));
    } else {
      setSearchText(undefined);
    }
  };

  function handleShow(value) {}
  function onTopSearch(value) {}

  useEffect(() => {
    dispatch(getAllTechnicians());
    dispatch(getTechnicains(1, limit, ""));
  }, []);

  useEffect(() => {
    techlistApi && setTechnicians(techlistApi);
  }, [techlistApi]);

  useEffect(() => {
    techniciansData &&
      techniciansData.rows &&
      setTechniciansAssigned(
        techniciansData.rows.map((item) => ({
          id: item?.val.id,
          first_name: item?.val.name,
          email: item?.email,
          number: item?.contact_number,
          speciality: item?.val?.PartyRole?.Technician?.TechnicianCategory,
          techid: item?.val?.PartyRole?.Technician?.id,
        })),
      );
  }, [techniciansData]);

  const onSubmit = () => {
    dispatch(getAllTechnicians());
    setVisible(false);
    setTID(null);
    setSpecial([]);
  };

  const onAddTech = () => {
    dispatch(getTechnicains(1, limit, ""));
    setPage(1);
    setVisible(false);
  };

  const onPageChange = (e) => {
    setPage(e);
    dispatch(getTechnicains(e, limit, searchText));
  };

  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Add New Technician
        </Title>
      ),
      content: (
        <AddTechnician
          onAddTech={onAddTech}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          New Technician Request Approval
        </Title>
      ),
      content: (
        <ApproveTechnician
          data={techData}
          onReject={rejectTechnician}
          onCancel={() => setVisible(false)}
          onApprove={onSubmit}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Reason for Rejection
        </Title>
      ),
      content: (
        <RejectTechnician
          data={techData}
          onReject={onSubmit}
          onBack={() => setTrigger(1)}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Add/Remove Specialization
        </Title>
      ),
      content: (
        <AddRemoveSpecialzation
          onAddTech={onAddTech}
          data={special}
          id={tid}
          onCancel={() => {
            setVisible(false);
            setSpecial([]);
          }}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  function rejectTechnician() {
    setTrigger(2);
  }

  const onClickFilter = () => {
    console.log("filter click");
  };

  const addTechnician = () => {
    setVisible(true);
    setTrigger(0);
  };

  const updateSpecialization = (item, id) => {
    setVisible(true);
    setTrigger(3);
    setSpecial(item);
    setTID(id);
  };

  const approveTechnician = (item) => {
    setVisible(true);
    setTechData(item);
    setTrigger(1);
  };

  return (
    <>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Technicians"
              total={
                tabKey == "1" ? technicians.length : techniciansData?.count
              }
              //showOption={showOption}
              //handleShow={handleShow}
              // search={onTopSearch}
              // filterAction={onClickFilter}
              addNewAction={addTechnician}
            />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Tabs
                  defaultActiveKey={tabKey}
                  onChange={(key) => setTabkey(key)}
                  tabBarExtraContent={searchField}
                  tabBarGutter={48}
                  className="ag-property-status"
                >
                  <TabPane tab="UnAssgined" key="1">
                    <Grid
                      data={[]}
                      columns={technicianCol}
                      pagination={false}
                      defaultSettings={gridProps}
                      // filterGrid={searchText}
                      noRowlabel="Technicians"
                      noRowbtnAction={addTechnician}
                    />
                  </TabPane>
                  <TabPane tab="Assigned" key="2">
                    <Row gutter={[20, 20]} justify="end">
                      <Col span={24}>
                        <Grid
                          data={techniciansAssigned}
                          columns={assignTechCol}
                          pagination={false}
                          defaultSettings={gridProps}
                          filterGrid={searchText}
                          noRowlabel="Technicians"
                          noRowbtnAction={addTechnician}
                        />
                      </Col>
                      <Col span={24}>
                        <Pagination
                          style={{ justifyContent: "flex-end" }}
                          className="ag-property-text-pagination"
                          total={techniciansData?.count}
                          defaultPageSize={limit}
                          current={page}
                          onChange={(e) => onPageChange(e)}
                          showSizeChanger={false}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};
