import axios from "../utils/axiosInceptor";

import { propertyService } from "../configs/constants";

const firstUploadUrl = `${propertyService}/property/upload-signed-url`;
const firstJson = {
  keys: [],
};

export const uniquiFileName = (name) => {
  const replaceAbleKey = `eqarcom_${
    Date.now() + String(Math.random().toString().slice(2, 4))
  }`;
  const ext = name.split(".").pop();
  return `${replaceAbleKey}.${ext}`;
};

const readeFile = async (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result);
    });
    reader.readAsArrayBuffer(file);
  });
};
export const getSingleUpload = async (fileName, file) => {
  const postJson = {
    keys: [fileName],
  };
  try {
    const dataLoad = await readeFile(file);
    return await axios.post(firstUploadUrl, postJson).then((response) => {
      if (response?.data?.result?.signedUrl) {
        const fileHeaders = {
          "Content-type": file?.type,
        };
        /* axios.put(response?.data?.result?.signedUrl[0], file, {
          headers: fileHeaders,
        }); */
        // @ts-ignore
        const body: any = {
          method: "PUT",
          body: dataLoad,
        };
        fetch(response?.data?.result?.signedUrl[0], body);
        return true;
      }
    });
  } catch (e) {
    console.log("Err", e);
    return false;
  }
};

let multiFileCached = [];
export const getMultipleUpload = async (fileKey, setMultiImageKey) => {
  multiFileCached = fileKey.map((e) => uniquiFileName(e.name));
  fileKey.forEach((e) => firstJson.keys.push(uniquiFileName(e.name)));
  firstJson.keys = Array.from(new Set(firstJson.keys));
  setMultiImageKey(firstJson.keys);

  try {
    if (multiFileCached.length === firstJson.keys.length) {
      axios.post(firstUploadUrl, firstJson).then(async (response) => {
        if (response?.data?.result?.signedUrl) {
          const urlWithFileArray = [];
          for (let i = 0; i < response?.data?.result?.signedUrl.length; i++) {
            const urlWithFileObj = {
              signedUrl: response?.data?.result?.signedUrl[i],
              file: fileKey[i]?.originFileObj,
              fileHead: {
                "Content-type": fileKey[i]?.originFileObj?.type,
              },
            };
            urlWithFileArray.push(urlWithFileObj);
          }
          await Promise.all(
            urlWithFileArray.map(async (e) => {
              return await axios.put(e.signedUrl, e.file, {
                headers: e.fileHead,
              });
            }),
          );
        }
      });
    }
  } catch (e) {
    console.log("Err", e);
    return false;
  }
};
