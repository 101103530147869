import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Typography,
  Card,
  Select,
  message,
  Button,
  Spin,
  Space,
} from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import Grid from "../../../atoms/Grid/";
import { Popup } from "../../../atoms/Popup";
import SectionHeader from "../../../molecules/SectionHeader";

import AddZone from "./components/AddZone";
import BreadCrumb from "../../../atoms/BreadCrumb";
import DashboardLayout from "../../../molecules/DashboardLayout";
import { getAreaStatus, getListZones, getBuilding } from "./ducks/actions";
import { maintenanceService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import AddBuilding from "./components/AddBuilding";
import { EditOutlined } from "@ant-design/icons";
import EditZone from "./components/EditZone";

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;
const { Option } = Select;

const gridProps = {
  sortable: true,
  filter: false,
};

const showOption = [
  {
    value: "All",
    label: "All Zones",
  },
  {
    value: "new",
    label: "New Zone",
  },
];

const breadcrumbList: any = [
  {
    label: "Maintenance",
    route: "/zones",
  },
  {
    label: "Zones",
    route: "/zones",
  },
];

export default () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [searchText, setSearchText] = useState();
  const [zones, setZones] = useState([]);
  const [zoneId, setZoneid] = useState(null);
  const [areaStatus, setAreaStatus] = useState([]);
  const [zoneData, setZoneData] = useState({});
  const zoneApi = useSelector((state) => state.zones.zonesData);
  const areastatusApi = useSelector((state) => state.zones.statusData);
  const searchField = (
    <Input
      type="text"
      onChange={(e) => onSearch(e)}
      placeholder=""
      suffix={<SearchOutlined />}
    />
  );
  let [refreshtrigger, setrefreshtrigger] = useState(null);

  const handleBuilding = (data) => {
    console.log(data)
    setVisible(true);
    setTrigger(1);
    setZoneid(data?.data.id);
    setZoneData(data)

  };

  const statusComponent = (params) => {
    return (
      <Select
        className={`Sentence ${
          params.data?.status.name === "active"
            ? "select-bg-green"
            : "select-bg-red"
        }`}
        defaultValue={params.data?.status.id}
        onSelect={(value, option) => onStatusChange(value, option, params.data)}
        style={{ width: 150 }}
      >
        {areaStatus.length &&
          areaStatus.map((item) => {
            return (
              <React.Fragment key={item.id}>
                <Option value={item.id} className="Sentence">
                  {item.name}
                </Option>
              </React.Fragment>
            );
          })}
      </Select>
    );
  };

  const propertiesComponent = (params) => {
    return (
      <Select
        className="Sentence"
        style={{ width: 150 }}
        defaultValue={params.data?.properties[0]?.Asset?.id}
        dropdownRender={(menu) => (
          <>
            {/* <Button
              type="link"
              className="fontWeight600 pl-1"
              onClick={() => handleBuilding(params)}
            >
              Add a Building
            </Button> */}
            {menu}
          </>
        )}
      >
        {params.data?.properties?.length > 0 &&
          params.data.properties.map((item, index) => (
            <React.Fragment key={index}>
              <Option value={item.Asset?.id} className="Sentence">
                {item.Asset?.name}
              </Option>
            </React.Fragment>
          ))}
        {/* <Option value="custom" className="ag-success pl-1 fontWeight600">Add a Building</Option> */}
      </Select>
    );
  };
  const actionEdit = (params) => {
    return (
        <>
          <Space size={16}>
            <Button type="link" 
            onClick={() => handleUpdate(params)}
            className="p-0 ag-success">
              <EditOutlined />
            </Button>
          </Space>
        </>
    )
}
  const zoneCol = [
    {
      headerName: "ID",
      valueGetter: "node.rowIndex + 1",
      maxWidth: 120,
    },
    {
      headerName: "Zones",
      field: "name",
      maxWidth: 250,
    },

    {
      headerName: "Assigned Building",
      field: "properties",
      cellRendererFramework: propertiesComponent,
    },

    {
      headerName: "Status",
      field: "status",
      maxWidth: 250,
      cellRendererFramework: statusComponent,
    },
    {
      headerName: "Actions",
      field: "action",
      maxWidth: 250,
      cellRendererFramework: actionEdit,
    },
  ];




  const handleUpdate = (data) => {
    setVisible(true);
    setTrigger(2);
    setZoneData(data)
  };


  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Add Zone
        </Title>
      ),
      content: (
        <AddZone onCancel={() => setVisible(false)} onSubmit={onSubmit} />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Assign a Building
        </Title>
      ),
      content: (
        <AddBuilding
          id={zoneId}
          onCancel={() => setVisible(false)}
          onSubmit={onSubmit}
          zoneData={zoneData} 
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Edit Zone
        </Title>
      ),
      content: (
        <EditZone onCancel={() => setVisible(false)} zoneData={zoneData} onSubmit={onSubmit} />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  useEffect(() => {
    dispatch(getAreaStatus());
    dispatch(getListZones());
    dispatch(getBuilding());
  }, []);

  useEffect(() => {
    areastatusApi && setAreaStatus(areastatusApi);
  }, [areastatusApi]);

  useEffect(() => {
    if (zoneApi) {
      let zoneArray: any = zoneApi.map((item, index) => {
        return {
          id: item.id,
          name: item.name,
          properties: item.MaintenanceZone,
          status: item.ZoneStatus.ZoneStatusType,
          dataDump:zoneApi.find(data => data.id === item.id),
        };
      });
      setZones(zoneArray);
    }
  }, [zoneApi]);

  function onSubmit() {
    dispatch(getBuilding());
    dispatch(getListZones());
    setVisible(false);
  }

  function handleShow(value) {}
  function handleView(value) {}
  function onTopSearch(value) {}
  async function onStatusChange(value, option, param) {
    // console.log("status", option, value, param)
    setLoading(true);
    const url = `${maintenanceService}/maintenance/updateZoneStatus/${param.id}`;
    try {
      await axios.put(url, { status: option.children });
      setTimeout(() => {
        setLoading(false);
        message.success("Zone successfully updated");
        dispatch(getListZones());
      }, 3000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.data?.message);
    }
  }

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const onClickFilter = () => {
    console.log("filter click");
  };

  function onClickImport() {}

  const addZone = () => {
    setVisible(true);
    setTrigger(0);
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Zones"
              total={zones.length}
              // showOption={showOption}
              // handleShow={handleShow}
              // search={(e) => onTopSearch(e)}
              // importAction={onClickImport}
              // filterAction={onClickFilter}
              addNewAction={addZone}
            />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Card
                  bordered={false}
                  className="ag-nospace-body"
                  extra={searchField}
                >
                  {areaStatus.length > 0 && (
                    <Grid
                      refCol="status"
                      data={zones}
                      columns={zoneCol}
                      pagination={false}
                      defaultSettings={gridProps}
                      filterGrid={searchText}
                      noRowlabel="Zones"
                      noRowbtnAction={addZone}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </Spin>
  );
};
