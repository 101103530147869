import React from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import { Card, Space, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";

const { Text, Title } = Typography;
declare const google: any;
import Pin from "../assets/img/pin.svg";

export const WrappedMap = withScriptjs<any>(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{ lat: 25.276987, lng: 55.296249 }}
    >
      {props.positions.map((position) =>
        props.dashboard === false ? (
          <Marker
            key={position.id}
            position={{
              lat: parseFloat(position.text.lat),
              lng: parseFloat(position.text.lng),
            }}
            icon={{
              url: Pin,
              scaledSize: new google.maps.Size(48, 48),
            }}
            // labelStyle={{
            //   width: 64,
            //   backgroundColor: "white",
            //   fontSize: "11px",
            //   padding: "8px",
            //   borderRadius: 8,
            //   boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.19)",
            // }}
            // labelAnchor={{ x: 30, y: 80 }}
            onClick={() => props.selected(position)}
          >
            {/* <div>Assigned</div> */}
          </Marker>
        ) : (
          <Marker
            key={position.id}
            position={{
              lat: parseFloat(position.text.lat),
              lng: parseFloat(position.text.lng),
            }}
            icon={{
              url: Pin,
              scaledSize: new google.maps.Size(48, 48),
            }}
          />
        ),
      )}
      {props.selectedPark != null && (
        <InfoWindow
          options={{ width: 269, maxHeight: 425 }}
          position={{
            lat: parseFloat(props.selectedPark?.firedata.text.lat),
            lng: parseFloat(props.selectedPark?.firedata.text.lng),
          }}
          onCloseClick={() => props.selected(null)}
        >
          <>
            <Space direction="vertical" size={0} className="w-100">
              <Text className="fontSize11">
                Technician id:{" "}
                {props.selectedPark?.firedata.id.replace("user-", "")}
              </Text>
              <Text
                className={`fontSize11 fontWeight600 ${
                  !props.ticketList.new && !props.ticketList.inprogress
                    ? "ag-success"
                    : "ag-primary"
                }`}
              >
                {props.ticketList?.new?.length === 0 &&
                props.ticketList?.inprogress.length === 0
                  ? "Available"
                  : "Assigned"}
              </Text>
              <Space
                direction="vertical"
                size={16}
                align="center"
                className="w-100"
              >
                {console.log(
                  "addd",
                  props.ticketList?.new?.length != 0,
                  props.ticketList?.inprogress.length != 0,
                )}
                <Avatar
                  size={110}
                  gap={4}
                  style={{ fontSize: 64 }}
                  className={`${
                    props.ticketList?.new?.length === 0 &&
                    props.ticketList?.inprogress.length === 0
                      ? "greenbg"
                      : "bluebg"
                  }`}
                >
                  {(props.selectedPark?.firedata?.text?.technician_name).charAt(
                    0,
                  )}
                </Avatar>
                <Space
                  size={0}
                  direction="vertical"
                  className="w-100"
                  align="center"
                >
                  <Title
                    level={5}
                    className={`m-0 ${
                      !props.ticketList.new && !props.ticketList.inprogress
                        ? "ag-success"
                        : "ag-primary"
                    }`}
                  >
                    {props.selectedPark?.firedata.text?.technician_name}
                  </Title>
                  <Text className="fontSize11">Technician</Text>
                </Space>
              </Space>
            </Space>
            <Text className="mt-2 mb-2">Tickets</Text>
            {props.ticketList && (
              <Card
                style={{ maxHeight: 140, overflow: "auto", minHeight: 0 }}
                bodyStyle={{ background: "#EEF1FA", padding: 8 }}
              >
                {props.ticketList.new &&
                  props.ticketList.new.map((item, index) => (
                    <Text key={index} className="d-block lineHeight28">
                      Ticket: {item.case_id}
                    </Text>
                  ))}
                {props.ticketList.inprogress &&
                  props.ticketList.inprogress.map((item, index) => (
                    <Text key={index} className="d-block lineHeight28">
                      Ticket: {item.case_id}
                    </Text>
                  ))}
              </Card>
            )}
          </>
        </InfoWindow>
      )}
    </GoogleMap>
  )),
);
