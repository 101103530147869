import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  Space,
  Spin,
  message,
  Typography
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { DateField, InputField, SelectField } from "../../../../../atoms/FormElement";
import moment from 'moment';
// import { getAllZones } from '../../ducks/actions';
// import { getTicketType } from '../../../Tickets/ducks/actions';
import { maintenanceService } from '../../../../../../configs/constants';
import axios from '../../../../../../utils/axiosInceptor'

const initValue: any = {
  email: '',
  first_name: '',
  status: '',
  joining_date: '',
  zone: [],
  speciality_ids: []
}

const { Paragraph } = Typography;

const ApproveTechnician = props => {

  const dispatch = useDispatch()
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dataTech, setDataTech] = useState(initValue)
  const [zones, setZones] = useState([]);
  const [types, setTypes] = useState([]);
  const [joinDate, setJoinDate] = useState();
  const [special, setSpecial] = useState();
  const { control, errors, reset, handleSubmit, setValue } = useForm();

  const submituUrl = `${maintenanceService}/maintenance/technician/assign-building/`;

  // const zoneApi = useSelector(state => state.technicians.zonesData);
  // const typeApi = useSelector(state => state.tickets.ticketsType);

  useEffect(() => {
    // if (!zoneApi.length) {
    //   console.log('nozone')
    //   dispatch(getAllZones());
    // }
    // if (!typeApi.length) {
    //   console.log('notype')
    //   dispatch(getTicketType());
    // }
  }, [])

  useEffect(() => {
    if (props.data) {
      setDataTech(props.data.data)
      setValue("speciality_ids", props.data.data.speciality.map(item => ({ value: item.id, label: item.display_name })))

    }
  }, [props.data])

  useEffect(() => {
    if (dataTech) {
      let jdate: any = moment(`${dataTech.joining_date}`);
      setValue("joining_date", jdate);
    }
  }, [dataTech])

  // useEffect(() => {
  //   zoneApi && setZones(zoneApi.map(item => ({ value: item.id, label: item.name })));
  // }, [zoneApi])

  // useEffect(() => {
  //   typeApi && setTypes(typeApi.map(item => ({ value: item.ChildCaseCategory.id, label: item.ChildCaseCategory.name })));
  // }, [typeApi]);



  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true)
    try {
      await axios.get(`${submituUrl}${dataTech.id}/approve`);
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        message.success(`Technician Approved`);
        props.onApprove();
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      // message.error(response?.data?.message);
      setErrorMessage(response?.data?.message)
    }
  }

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onFinish)}
    >
      {loader && !isError &&
        (<Row gutter={[24, 24]} >
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>)}
      {!submited ? <>
        <Row gutter={24}>
          <Col span={12}>
            <InputField
              fieldname="first_name"
              label="Name"
              control={control}
              iProps={{ readOnly: true, bordered: false, className: 'p-0' }}
              initValue=''
              setValue={setValue}
              valueGot={dataTech?.first_name}
            />
          </Col>
          <Col span={12}>
            <InputField
              fieldname="email"
              label="Email Addresss"
              control={control}
              iProps={{ readOnly: true, bordered: false, className: 'p-0' }}
              initValue=''
              setValue={setValue}
              valueGot={dataTech?.email}
            />
          </Col>
          <Col span={12}>
            <DateField
              // isRequired={true}
              fieldname="joining_date"
              label="Date of Joining"
              control={control}
              iProps={{ format: "DD-MM-YYYY", disabled: true }}
              // rules={{
              //   required: "Please enter joining date",
              //   setValueAs: (value) => moment(value).format('YYYY-MM-DD'),
              // }}               
              initValue=""
            // validate={errors.joining_date && "error"}
            // validMessage={errors.joining_date && errors.joining_date.message}
            />

          </Col>
          <Col span={12}>
            {/* <InputField
              fieldname="speciality_ids"
              label="Speciality"
              control={control}
              iProps={{ readOnly: true }}
              initValue=''
              setValue={setValue}
              valueGot={special}
            /> */}
            <SelectField
              // isRequired={true}
              fieldname="speciality_ids"
              label="Speciality"
              control={control}
              iProps={{ placeholder: 'No Speciality', isMulti: true, isDisabled: true }}
              // rules={{ required: "Please select speciality" }}
              initValue=''
              selectOption={types}
            // validate={errors.speciality_ids && "error"}
            // validMessage={errors.speciality_ids && errors.speciality_ids.message}
            />
          </Col>

          <Col span={12}>
            <InputField
              fieldname="zone"
              label="Zone"
              control={control}
              iProps={{ readOnly: true, placeholder: 'Not assigned to any zone' }}
              initValue=''
              setValue={setValue}
              valueGot={dataTech && dataTech.zone[0]?.name}
            />
            {/* <SelectField
              // isRequired={true}
              fieldname="zone_id"
              label="Zone"
              control={control}
              iProps={{ placeholder: 'Select Zone' }}
              // rules={{ required: "Please select zone" }}
              initValue=''
              selectOption={zones}
              setValue={setValue}
              valueGot={zones.filter(item => item.value === dataTech?.zone.id)}
            // validate={errors.zone_id && "error"}
            // validMessage={errors.zone_id && errors.zone_id.message}
            /> */}
          </Col>

        </Row>
        <Row gutter={24} justify="space-between">
          <Col>
            <Button className="ag-gray-button-outline" htmlType="button" onClick={props.onReject}>Reject</Button>
          </Col>
          <Col>
            <Button className="ag-green-button" htmlType="submit">Approve</Button>
          </Col>
        </Row>
      </>
        :
        isError &&
        (<>
          <Row gutter={[24, 24]} >
            <Col span={24}>
              <Space size={24} className="w-100 text-center" direction="vertical">
                <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                <Paragraph className="ag-error fontWeight600 mb-0">{errorMessage}</Paragraph>
              </Space>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}><Button className="ag-gray-button-outline" htmlType="button" onClick={() => { setSubmited(false); setisError(false) }}>Back</Button></Col>
          </Row>
        </>)
      }
    </Form>
  );
};

export default ApproveTechnician;
