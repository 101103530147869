// import axios from "axios";
import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { maintenanceService } from "../../../../configs/constants";

const assignedUrl = `${maintenanceService}/maintenance/fetchAssignedTickets`;
const countUrl = `${maintenanceService}/maintenance/fetchTicketsCount`;
const ticketStatusUrl = `${maintenanceService}/facilityManager/getSagrigatedTicketsCount`;
const urlTech = `${maintenanceService}/technician/getTicketsWithoutAuth`;

// let partyid = JSON.parse(window.localStorage.getItem("login-response"))?.data.userId;

export const getAssignedList = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(assignedUrl);
      dispatch({
        type: action_types.GET_ASSIGNED_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getSegregatedCount = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(countUrl);
      dispatch({
        type: action_types.GET_SEGREGATED_COUNT,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTicketStatus = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(ticketStatusUrl);
      dispatch({
        type: action_types.GET_TICKET_STATUS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTechTicket = (id, status) => {
  return async (dispatch) => {
    try {
      let url = `${urlTech}?logged_in_party_id=${id}&status=${status}&totalRecords=`;
      const {
        data: { result },
      } = await axios.get(url);
      if (status === "new") {
        dispatch({
          type: action_types.GET_TICKET_TECHNICIAN,
          data: result,
        });
      } else {
        dispatch({
          type: action_types.GET_TICKET_TECHNICIAN2,
          data: result,
        });
      }
    } catch (e) {
      console.log("Err", e);
    }
  };
};
