import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAssignedList } from "../../ducks/actions";
import Grid from "../../../../atoms/Grid";

const gridProps = {
  sortable: true,
  filter: false,
};

const technicianCol = [
  {
    headerName: "Technician Name",
    field: "name",
  },
  {
    headerName: "Tickets Type",
    field: "ticket_type",
    maxWidth: 150,
  },
  {
    headerName: "Location",
    field: "location",
    maxWidth: 100,
  },
  {
    headerName: "Status",
    field: "status",
  },
];

export default (props) => {
  const dispatch = useDispatch();
  const [assigned, setAssigned] = useState([]);
  const assignedList = useSelector((state) => state.maintenance.assignedData);

  useEffect(() => {
    dispatch(getAssignedList());
  }, []);

  useEffect(() => {
    setAssigned(assignedList);
  }, [assignedList]);

  return (
    <Card bordered={false} className="ag-nospace-body" title="Asssigned">
      <Grid
        data={assigned}
        columns={technicianCol}
        smallGrid={true}
        pagination={false}
        defaultSettings={gridProps}
        noRowlabel="Technicians"
      />
    </Card>
  );
};
